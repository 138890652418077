import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { DropzoneArea } from 'material-ui-dropzone'
import '../styles/step3.css'
import { withApollo } from 'react-apollo'
import Typography from '@material-ui/core/Typography'

import {UPLOAD_FILE_STEP3_MUTATION} from '../mw/mutations'
class Step3 extends Component {
  state = {
    files: [],
    file: null
  }

  validateFile = () => {
    const { file } = this.state
    this.setState({ errorFile: false, messageFile: '' })
    if (file === null) {
      this.setState({ errorFile: true, messageFile: 'Proszę załączyć plik!' })
    } else {
      const fileBase64 = file
      this.props.client
        .mutate({
          mutation: UPLOAD_FILE_STEP3_MUTATION,
          variables: {
            fileBase64
          }
        })
        .then(data => {
  
        })
        .catch(error => {
          console.log(error)
        })
        this.props.onRefresh();
    }
  }

  readFile = async file => {
    let fileTypes = ['zip', 'rar']
    if (file === undefined) {
      return
    }
    var extension = file.name
      .split('.')
      .pop()
      .toLowerCase()
    let isSuccess = fileTypes.indexOf(extension) > -1
    if (isSuccess === true) {
      this.setState({
        reading: true,
        importError: false,
        errorFile: false,
        messageFile: ''
      })
      const reader = new FileReader()
      await reader.readAsDataURL(file)
      reader.onload = async () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        this.setState(
          { file: encoded, reading: false, ifError: false },
          () => {}
        )
      }
      reader.onerror = err => reader.abort()
    } else {
      this.setState({ errorFile: true, messageFile: 'Błędny format pliku!' })
    }

    await this.setState({ reading: false })
  }

  handleChange (files) {
    this.setState({
      files: files
    })
  }

  render () {
    return (
      <div className='upload-container-step3'>
        <div className='form-container-top-bar-s3'>
          <div className='form-container-top-bar-left-s3'>
            Przed Tobą ostatni kroki rekrutacji. Spakuj wszystkie potrzebne
            skany (świadectwo ukończenia szkoły oraz wyniki testów) do formatu
            zip i prześlij je tutaj do systemu.
            <br /> My niezwłocznie skontaktujemy się z Tobą.
          </div>
          <div className='form-container-top-bar-right-s3'></div>
        </div>
        {this.state.errorFile === true && this.state.messageFile !== '' && (
          <Typography variant='h5' style={{ color: 'red', marginBottom: 20 }}>
            {' '}
            {this.state.messageFile}
          </Typography>
        )}
        <DropzoneArea
          dropzoneText='Dodaj katalog w formacie .zip'
          onChange={event => {
            this.readFile(event[0])
          }}
          dropzoneParagraphClass='dropzone'
          useChipsForPreview={true}
          acceptedFiles={['application/x-zip-compressed', 'application/zip', 'multipart/x-zip']}
          filesLimit={1}
        />
        <Button
          variant='contained'
          disableElevation
          style={{
            backgroundColor: '#25017C',
            color: '#fff',
            marginLeft: 30,
            alignSelf: 'flex-end',
            borderRadius: 20,
            borderWidth: 2,
            marginTop: 30
          }}
          onClick={this.validateFile}
        >
          Wyślij
        </Button>
      </div>
    )
  }
}
export default withApollo(Step3)
