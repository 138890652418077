import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "../styles/step2.css";
import { withApollo } from "react-apollo";

import {SUBMIT_TEST_MUTATION} from '../mw/mutations'

class Step2 extends Component {
  state = {
    res1: "",
    res2: "",
    res3: "",
    res4: "",
    res5: "",
    res6: "",
    res7: "",
    res8: "",
    res9: "",
    res10: "",
    res11: "",
    res12: "",
    res13: "",
    res14: "",
    res15: "",
    res16: "",
    res17: "",
    a17: "",
    b17: "",
    c17: "",
    d17: "",
    e17: "",
    res18: "",
    a18: "",
    b18: "",
    c18: "",
    d18: "",
    e18: "",
    res19: "",
    res20: "",
    ready: false
  };
  sendTest = () => {
    const {
      res1,
      res2,
      res3,
      res4,
      res5,
      res6,
      res7,
      res8,
      res9,
      res10,
      res11,
      res12,
      res13,
      res14,
      res15,
      res16,
      res19,
      res20
    } = this.state;

    let arr1 = [];
    arr1[0] = this.state.a17;
    arr1[1] = this.state.b17;
    arr1[2] = this.state.c17;
    arr1[3] = this.state.d17;
    arr1[4] = this.state.e17;
    const res17 = arr1.join("\r\n");
    let arr2 = [];
    arr2[0] = this.state.a18;
    arr2[1] = this.state.b18;
    arr2[2] = this.state.c18;
    arr2[3] = this.state.d18;
    arr2[4] = this.state.e18;
    const res18 = arr2.join("\r\n");

    this.props.client
      .mutate({
        mutation: SUBMIT_TEST_MUTATION,
        variables: {
          res1,
          res2,
          res3,
          res4,
          res5,
          res6,
          res7,
          res8,
          res9,
          res10,
          res11,
          res12,
          res13,
          res14,
          res15,
          res16,
          res17,
          res18,
          res19,
          res20
        }
      })
      .then(results => {
        this.props.onRefresh();
      })
      .catch(error => {});
  };
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    return (
      <div className="test-container">
        <div className="form-container-top-bar">
          <div className="form-container-top-bar-left">
            Test składa się z 20 zadań podzielonych na trzy tematyki:
            matematyka, informatyka, język angielski. Usiądź wygodnie w fotelu i
            postaraj się rozwiązać zadania. Rozwiązanie testu powinno Ci zająć
            od 40 do 90 minut w zależności od Twojego skupienia. Test wspomaga
            decyzję o przyjęciu do szkoły, nie jest on determinującym
            wskaźnikiem.
            <br />
            NIE STRESUJ SIĘ! POWODZENIA!
          </div>
          <div className="form-container-top-bar-right"></div>
        </div>
        <div className="test-container-top">
          <Typography variant="subtitle1">ZADANIA Z MATEMATYKI</Typography>

          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 1
          </Typography>
          <p className="test-desc">
            Liczba 1450 jest zaokrągleniem do rzędu dziesiątek kilku liczb
            naturalnych. Ile jest wszystkich liczb naturalnych różnych od 1450,
            które mają takie zaokrąglenie? Wybierz właściwą odpowiedź spośród
            podanych.
            <br />
            <br />
            ⦁ 4
            <br />
            ⦁ 5
            <br />
            ⦁ 9
            <br />⦁ 10
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            <TextField
              id="res1"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res1}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 2</Typography>
          <p className="test-desc">
            Poniżej znajdują się trzy wyrażenia. Które z tych wyrażeń są równe
            50<sup>8</sup>? Wybierz właściwą odpowiedź spośród podanych.
            <br />
            I. 5<sup>2</sup> * 10 <sup>8</sup> * 5 <sup>4</sup>
            <br />
            II. (5<sup>10</sup>: 5<sup>2</sup>) * 10<sup>8</sup>
            <br />
            III. 2<sup>8</sup> * 5<sup>8</sup> * 5<sup>8</sup>
            <br />
            <br />
            ⦁ Tylko I i II
            <br />
            ⦁ Tylko II i III
            <br />
            ⦁ Tylko II
            <br />⦁ Tylko III
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res2"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res2}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 3</Typography>
          <p className="test-desc">
            Dane są cztery wyrażenia. Wartości których wyrażeń są mniejsze od
            10? Wybierz właściwą odpowiedź spośród podanych.
            <br />
            I. 4 + &radic;35
            <br />
            II. 6 + &radic;17
            <br />
            III. 17 - &radic;48
            <br />
            IV. 15 - &radic;26
            <br />
            <br />
            ⦁ I i II
            <br />
            ⦁ II i III
            <br />
            ⦁ III i IV
            <br />⦁ I i IV
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res3"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res3}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 4</Typography>
          <p className="test-desc">
            Dorota sporządziła z cukru i wody syrop do deseru. Stosunek masy
            cukru do masy wody w tym syropie jest równy 5 : 3. Ile procent masy
            tego syropu stanowi masa cukru? Wybierz właściwą odpowiedź spośród
            podanych. <br />
            <br />
            ⦁ 25%
            <br />
            ⦁ 37,5%
            <br />
            ⦁ 40%
            <br />
            ⦁ 60%
            <br />⦁ 62,5%
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res4"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res4}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 5</Typography>
          <p className="test-desc">
            W pewnej firmie zatrudnionych jest więcej niż 10 pracowników. Połowa
            z nich zarabia po 3000 zł, a druga połowa 4000 zł za miesiąc pracy.
            Wybierz zdania które są prawdziwe. <br />
            <br /> I. Średnia arytmetyczna zarobków w tej firmie jest równa 3500
            zł. <br /> II. Gdy z pracy w tej firmie zrezygnują dwie osoby, z
            których jedna zarabia 3000 zł, a druga 4000 zł, to średnia
            arytmetyczna zarobków się nie zmieni.
            <br />
            <br />
            ⦁ Tylko I
            <br />
            ⦁ Tylko II
            <br />
            ⦁ I i II
            <br />⦁ Żadne
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res5"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res5}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 6</Typography>
          <p className="test-desc">
            Wyrażenie (2a + 3b)(3b-2a) jest równe? <br /> <br />
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res6"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res6}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 7</Typography>
          <p className="test-desc">
            W układzie współrzędnych wyznaczono odcinek o końcach w punktach K i
            L. Punkty te mają współrzędne K = (–17, 6) oraz L = (15, –4). Na
            którym rysunku zakropkowana część płaszczyzny zawiera środek odcinka
            KL? Wybierz właściwą odpowiedź spośród podanych. <br /> <br />
          </p>
          <div className="test-img1"></div>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res7"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res7}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 8</Typography>
          <p className="test-desc">
            Na rysunku przedstawiono trzy trójkąty. Dokończ zdanie. Wybierz
            właściwą odpowiedź spośród podanych. Na podstawie informacji
            przedstawionych na rysunku można stwierdzić, że: <br /> <br />
            ⦁ trójkąt ABC jest przystający do trójkąta KLM.
            <br />
            ⦁ trójkąt KLM jest przystający do trójkąta PQR.
            <br />
            ⦁ trójkąt PQR jest przystający do trójkąta ABC.
            <br />⦁ wszystkie trójkąty są do siebie przystające
          </p>
          <div className="test-img2"></div>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res8"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res8}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 9</Typography>
          <p className="test-desc">
            Samochód osobowy przebył drogę 120 km w czasie 75 minut. Prędkość
            średnia busa na tej samej trasie wyniosła 80 km/h . O ile krótszy
            był czas przejazdu tej drogi samochodem osobowym od czasu przejazdu
            busem? Zapisz obliczenia.
            <br />
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res9"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res9}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5">ZADANIE 10</Typography>
          <p className="test-desc">
            Adam zamówił bukiet złożony tylko z goździków i róż, w którym
            goździków było 2 razy więcej niż róż. Jedna róża kosztowała 4 zł, a
            cena jednego goździka wynosiła 3 zł. Czy wszystkie kwiaty w tym
            bukiecie mogły kosztować 35 zł? Uzasadnij odpowiedź. <br />
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res10"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res10}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="subtitle1">ZADANIA Z INFORMATYKI</Typography>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 11
          </Typography>
          <p className="test-desc">
            Najmniejsza jednostka informacji to:
            <br />
            <br />
            ⦁ bajt
            <br />
            ⦁ kilobajt
            <br />
            ⦁ bit
            <br />⦁ pakiet
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res11"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res11}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 12
          </Typography>
          <p className="test-desc">
            Odpowiedz na pytanie. Co powoduje życie klawisza F1 w większości
            programów w środowisku Windows?
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res12"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res12}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 13
          </Typography>
          <p className="test-desc">Odpowiedz na pytanie. Czym jest schowek? </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res13"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res13}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 14
          </Typography>
          <p className="test-desc">
            Odpowiedz na pytanie. Czym jest serwer w sieci komputerowej?{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res14"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res14}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 15
          </Typography>
          <p className="test-desc">
            Odpowiedz na pytanie. Co to jest Scratch?{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res15"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res15}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 16
          </Typography>
          <p className="test-desc">
            Odpowiedz na pytanie. Jakie przykłady projektów realizowałeś w
            Scratch? Opisz je swoimi słowami.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res16"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res16}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>

          <Typography variant="subtitle1">
            ZADANIA Z JĘZYKA ANGIELSKIEGO
          </Typography>

          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 17
          </Typography>
          <p className="test-desc">
            Zadaj pytanie do podkreślonej części zdania:{" "}
          </p>
          <p className="test-desc">
            We have got <u>five</u> new bicycles.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="a17"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.a17}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <p className="test-desc">
            She remembers <u>her first holidays</u>.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="b17"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.b17}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <p className="test-desc">
            Helen knows <u>Mr. Jones</u> very well.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="c17"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.c17}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <p className="test-desc">
            There is some <u>butter</u> on the plate.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="d17"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.d17}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <p className="test-desc">
            The boys sometimes <u>play</u> football.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="e17"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.e17}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>

          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 18
          </Typography>
          <p className="test-desc">Przetłumacz na język polski: </p>
          <p className="test-desc">He writes his own songs. </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="a18"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.a18}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <p className="test-desc">Have a look at this.</p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="b18"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.b18}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <p className="test-desc">
            Many teenagers like detective films best.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="c18"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.c18}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <p className="test-desc">
            He gets his books from the local library.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="d18"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.d18}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <p className="test-desc">
            There are a lot of famous stars in the film.{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="e18"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.e18}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 19
          </Typography>
          <p className="test-desc">
            Przeczytaj tekst o hotelu i zaznacz które zdania są prawdziwe:{" "}
            <br />
            <br /> THE MINSTER HOTEL 6, Davygate, York YO5 2QE Tel. 0904 653655
            Our hotel is in the centre of the city. It is only 50 metres from
            York Minster and very near the theatre, shopping centre, cinemas and
            restaurants. There are 50 bedrooms, each with a bathroom, a colour
            TV and a telephone. There is a car park behind the hotel.
            <br />
            <br /> I The hotel is far from the centre.
            <br /> II The hotel is not far from the shopping centre.
            <br /> III is at 6 Davygate in New York.
            <br />
            <br />
            ⦁ I <br />⦁ II <br />⦁ III <br />⦁ I i II
            <br />⦁ I i III
            <br />⦁ II i III <br />⦁ I i II i III <br />⦁ Żadne
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res19"
              onChange={this.handleChange}
              label="Odpowiedź:"
              value={this.state.res19}
              fullWidth
              variant="outlined"
              multiline={true}
            />
          </Grid>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            ZADANIE 20 (DODATKOWE)
          </Typography>
          <p className="test-desc">
            Opisz swoimi słowami (w języku polskim) jakie jest Twoje największe
            marzenie?{" "}
          </p>
          <Grid item xs={7} style={{ marginBottom: 30 }}>
            {" "}
            <TextField
              id="res20"
              onChange={this.handleChange}
              label="Odpowiedź:"
              multiline={true}
              value={this.state.res20}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {this.state.ready === true && (
            <Grid
              container
              style={{
                width: "80%",
                alignItems: "center",
                marginTop: 30,
                alignSelf: "center"
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ textAlign: "center", marginBottom: 30 }}
                >
                  CZY NA PEWNO CHCESZ ZAKOŃCZYĆ TEST?
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <Button
                  variant="outlined"
                  onClick={this.sendTest}
                  style={{
                    backgroundColor: "#25017C",
                    color: "#fff",
                    alignSelf: "flex-end",
                    borderRadius: 20,
                    borderWidth: 2,
                    fontSize: 18,
                    paddingLeft: 30,
                    paddingRight: 30
                  }}
                >
                  TAK
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ ready: false });
                  }}
                  style={{
                    backgroundColor: "#25017C",
                    color: "#fff",
                    borderRadius: 20,
                    borderWidth: 2,
                    fontSize: 18,
                    paddingLeft: 30,
                    paddingRight: 30
                  }}
                >
                  NIE
                </Button>
              </Grid>
            </Grid>
          )}
          {this.state.ready === false && (
            <Button
              variant="outlined"
              onClick={() => {
                this.setState({ ready: true });
              }}
              style={{
                backgroundColor: "#25017C",
                color: "#fff",
                marginLeft: 30,
                alignSelf: "center",
                borderRadius: 20,
                borderWidth: 2,
                marginTop: 30,
                fontSize: 18
              }}
            >
              Zakończ test
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default withApollo(Step2);
