import React, { Component } from "react";
import "../styles/login.css";
import WhiteLogo from "../images/svg/whiteLogo";
import PurpleLogo from "../images/svg/purpleLogo";
import LostPasswordForm from "../components/lostPasswordForm";
import history from "../utils/history";
import { Link } from "react-router-dom";

export default class LostPasswordPage extends Component {
  render() {
    return (
      <div className="container">
        <div className="left-container">
          <div className="logo-container">
            <WhiteLogo className="white-logo" />
          </div>
        </div>
        <div className="right-container">
          <div className="right-logo-container">
            <PurpleLogo
              className="purple-logo"
              onClick={() => {
                history.push("/");
              }}
            />
            <LostPasswordForm />
            <div className="privacy-policy">
              {" "}
              <Link
                to="/files/RegulaminStrony.pdf"
                style={{ textDecoration: "none", color: "#000" }}
                download
                target="_blank"
              >
                Regulamin strony.
              </Link>{" "}
              <Link
                style={{ textDecoration: "none", color: "#000" }}
                to="/files/PolitykaPrywatnosci.pdf"
                download
                target="_blank"
              >
                Polityka prywatności
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
