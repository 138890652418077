import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import history from "../utils/history";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";

import { LOGIN_MUTATION } from '../mw/mutations';

const styles = theme => ({
  root: {
    flex: 6.5,
    "& .MuiTextField-root": {
      marginBottom: 20,
      width: "80%"
    },
    "& .MuiButton-root	": {
      marginTop: 40,
      borderRadius: 50,
      textTransform: "none",
      fontSize: 18,
      fontWeight: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 8,
      paddingBottom: 8
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "10%"
    }
  }
});

class LoginForm extends Component {
  state = {
    username: "",
    password: "",
    submited: null,
    message: "",
    error: false
  };

  loginMutation = async () => {
    const { username, password } = this.state;
    await this.setState({ error: false, message: "" });
    if (username === "" || password === "") {
      await this.setState({ error: true, message: "Proszę uzupełnić pola!" });
    } else {
      this.props.client
        .mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            username,
            password
          }
        })
        .then(data => {
          localStorage.setItem("token", data.data.tokenAuth.token);

          history.push("/panel");
        })
        .catch(error => {
          if (
            error.graphQLErrors[0].message === "Please, enter valid credentials"
          ) {
            this.setState({
              message: "Błędny login lub hasło.",
              error: true
            });
          } else {
            this.setState({
              message: error.graphQLErrors[0].message,
              error: true
            });
          }
        });
    }
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <form className={classes.root} noValidate autoComplete="off">
        {this.state.error === false && this.state.message === "" && (
          <p className="light-text">Zaloguj się do systemu.</p>
        )}

        {this.state.message !== "" && (
          <p className="light-text-red">{this.state.message}</p>
        )}
        <div>
          <TextField
            onChange={this.onChange}
            type="email"
            id="username"
            label="E-mail"
            value={this.state.username}
          />
        </div>
        <div>
          <TextField
            onChange={this.onChange}
            type="password"
            id="password"
            label="Hasło"
            value={this.state.password}
          />
        </div>

        <Grid container spacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <span
              style={{ padding: 9, display: "inline-flex" }}
              onClick={() => {
                history.push("/lost_password");
              }}
            >
              Zapomniałeś hasło?
            </span>
          </Grid>
          <Grid item xs={6} style={{ padding: 0 }}>
            <Button
              variant="outlined"
              onClick={() => {
                history.push("/register");
              }}
              style={{
                borderColor: "#1B20A3",
                color: "#1B20A3",
                marginLeft: 30,
                fontSize: 14
              }}
            >
              Zarejestruj się
            </Button>
          </Grid>
          <Grid item xs={6} style={{ padding: 0 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#25017C",
                color: "#fff",
                fontSize: 14
              }}
              onClick={this.loginMutation}
            >
              Zaloguj się
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withApollo(withStyles(styles)(LoginForm));
