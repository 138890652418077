import React from 'react'
import { Slide } from 'react-slideshow-image'
import '../styles/swiper.css'

const properties = {
  duration: 10000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  onChange: (oldIndex, newIndex) => {}
}

const Slideshow = () => {
  return (
    <div className='slide-container'>
      <p className='title-text-swiper'>Rekomendacje</p>
      <Slide {...properties}>
        <div className='each-slide'>
          <div style={{ flexDirection: 'column' }}>
            <p className='top-text-swiper'>
              MAKS stempniewicz,
              <br /> EY Entrepreneur in Residence, Ex-Microsoft
            </p>
            <p className='bottom-text-swiper'>
              Szkoła która przygotowuje do nowej rzeczywistości <br />{' '}
              technologicznej i biznesowej.
            </p>
          </div>
        </div>
        <div className='each-slide'>
          <div style={{ flexDirection: 'column' }}>
            <p className='top-text-swiper'>
              dr NIKOS TSAVARAS
              <br /> Starszy wykładowca oraz Menadżer MT. SINAI RESEARCH LAB w
              Nowym Jorku
            </p>

            <p className='bottom-text-swiper'>
              Dane I sztuczna inteligencja zmieniają nawyki i zachowania <br />{' '}
              w otaczającym nas świecie. Techni Schools pokazuje, że rozumie i
              kształci <br /> uczniów we właściwym kierunku.
            </p>
          </div>
        </div>
        <div className='each-slide'>
          <div style={{ flexDirection: 'column' }}>
            <p className='top-text-swiper'>
              Łukasz Jeziorski,
              <br /> Prezes Zarządu Plum Research
            </p>

            <p className='bottom-text-swiper'>
              Młody człowiek ma w dzisiejszych czasach zupełnie nowe <br />{' '}
              możliwości rozwoju. Trzeba mu te możliwości pokazać. <br />
              Cieszymy się na partnerstwo z Techni Schools i pracę z uczniami.
            </p>
          </div>
        </div>
        <div className='each-slide'>
          <div style={{ flexDirection: 'column' }}>
            <p className='top-text-swiper'>
              dr Andrzej Zduniak,
              <br /> Rektor Wyższej Szkoły Bezpieczeństwa
            </p>

            <p className='bottom-text-swiper'>
              Współczesny model pracy i współżycia społecznego ma coraz
              silniejszy charakter cyfrowy. <br/> Cyfrowa rzeczywistość niesie za sobą
              wymierne korzyści, ale również realne zagrożenia. <br/> Edukacja
              programistyczna jest absolutnie kluczowa w budowaniu <br/> świadomości i
              bezpieczeństwa cybernetycznego.

            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default Slideshow
