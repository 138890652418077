import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import '../styles/step3.css'
import Typography from '@material-ui/core/Typography'
import FileSaver from 'file-saver'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import {SET_POINTS, SET_STATUS} from '../mw/mutations';

class StepAdm extends Component {
  constructor (props) {
    super(props)
    this.s1 = React.createRef()
  }

  state = {
    responses: null,
    loaded: false,
    points: '',
    id: null,
    pointsSet: null,
    message: ''
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value })
  }

  setPoints () {
    const { points } = this.state
    const { id } = this.props.user.userData.user
    this.props.client
      .mutate({
        mutation: SET_POINTS,
        variables: {
          id,
          points
        }
      })
      .then(results => {
        this.setState({
          pointsSet: results.data.setPoints.user.points,
          points: ''
        })
      })
      .catch(error => {})
    this.props.scrollTop()
    this.props.refreshPanel(id)
  }

  setStatus (type) {
    let wra = 'accepted'
    let accepted = true
    if (type !== 'accepted') {
      wra = 'rejected'
      accepted = false
    }
    const { message } = this.state
    const { id } = this.props.user.userData.user
    this.props.client
      .mutate({
        mutation: SET_STATUS,
        variables: {
          id,
          accepted,
          message,
          wra
        }
      })
      .then(results => {
        this.setState({ status: wra })
      })
      .catch(error => {
      })
      this.props.scrollTop()
      this.props.refreshPanel(id)
  }

  render () {
    return (
      <div className='upload-container-step3' ref={this.s1}>
        {this.props.user !== undefined && (
          <div style={{ textAlign: 'left' }}>
            {this.props.user.userData.user.waiRejAcc === 'accepted' && (
              <Typography
                variant='h3'
                style={{ marginBottom: 20, color: '#98eac7' }}
              >
                Rekrutacja zakończona, wniosek rozpatrzony pozytywnie
              </Typography>
            )}

            {this.props.user.userData.user.waiRejAcc === 'rejected' && (
              <Typography
                variant='h3'
                style={{ marginBottom: 20, color: '#f22565' }}
              >
                Rekrutacja zakończona, wniosek rozpatrzony negatywnie
              </Typography>
            )}
            {this.props.user.userData.user.waiRejAcc === 'waiting' &&
              this.props.user.userData.user.activeStep === 4 && (
                <Typography
                  variant='h3'
                  style={{ marginBottom: 20, color: '#844cd3' }}
                >
                  Wniosek oczekuje na decyzję.
                </Typography>
              )}
            <Typography
              variant='h4'
              style={{ textAlign: 'left', marginBottom: 20 }}
            >
              {'Użytkownik:' +
                ' ' +
                this.props.user.userData.user.name +
                ' ' +
                this.props.user.userData.user.surname}
            </Typography>
            {this.props.user.userData.user.active === false && (
              <Typography
                variant='h5'
                style={{ marginBottom: 20, color: 'red' }}
              >
                Konto nieaktywne
              </Typography>
            )}

            {this.props.user.userData.user.active === true && (
              <Typography variant='h5' style={{ marginBottom: 20 }}>
                Aktualny krok rekrutacji:{' '}
                {this.props.user.userData.user.activeStep}
              </Typography>
            )}

            <Typography variant='h5' style={{ marginBottom: 20 }}>
              Adres email: {this.props.user.userData.user.email}
            </Typography>
            {this.props.user.userFile !== null && (
              <div>
                <Button
                  variant='outlined'
                  onClick={() => {
                    const to = this.props.user.userFile.getadmindoc
                    const dataToPdf = this.props.user.userFile.getadmindoc.substring(
                      2,
                      to.length - 1
                    )
                    const blob = this.b64toBlob(dataToPdf, 'application/pdf')
                    FileSaver.saveAs(
                      blob,
                      `Kwestionariusz_${this.props.user.userData.user.name}_${this.props.user.userData.user.surname}`
                    )
                  }}
                  style={{
                    backgroundColor: '#25017C',
                    color: '#fff',
                    borderRadius: 30,
                    borderWidth: 2,
                    fontSize: 18,
                    width: '30%',
                    marginTop: 20,
                    marginBottom: 20
                  }}
                >
                  Pobierz kwestionariusz
                </Button>
              </div>
            )}
            {this.props.user.userCompressed !== null && (
              <div>
                <Button
                  variant='outlined'
                  onClick={() => {
                    const to = this.props.user.userCompressed.getadmincompressed
                    const dataToPdf = this.props.user.userCompressed.getadmincompressed.substring(
                      2,
                      to.length - 1
                    )
                    const blob = this.b64toBlob(dataToPdf, 'application/zip')
                    FileSaver.saveAs(
                      blob,
                      `Dokumenty_${this.props.user.userData.user.name}_${this.props.user.userData.user.surname}`
                    )
                  }}
                  style={{
                    backgroundColor: '#25017C',
                    color: '#fff',
                    borderRadius: 30,
                    borderWidth: 2,
                    fontSize: 18,
                    width: '30%',
                    marginTop: 20,
                    marginBottom: 20
                  }}
                >
                  Pobierz dokumenty
                </Button>
              </div>
            )}
            {this.props.user.userFile === null && (
              <Typography variant='h6' style={{ marginBottom: 20 }}>
                Brak przesłanego kwestionariusza
              </Typography>
            )}
            {this.props.user.userCompressed === null && (
              <Typography variant='h6' style={{ marginBottom: 20 }}>
                Brak przesłanych dokumentów
              </Typography>
            )}
            <Typography variant='h5' style={{ marginBottom: 20 }}>
              Odpowiedzi do testu:
            </Typography>

            {this.props.user.userResponses.userresponses === null && (
              <Typography variant='h6' style={{ marginBottom: 20 }}>
                Brak.
              </Typography>
            )}
            {(this.props.user.userData.user.points !== null ||
              this.state.pointsSet !== null) &&
              this.props.user.userData.user.points !== 0 && (
                <Typography variant='h5'>
                  Liczba punktów uzyskanych z testu:{' '}
                  {this.props.user.userData.user.points}
                </Typography>
              )}
            {this.props.user.userResponses.userresponses !== null &&
              this.props.user.userResponses.userresponses.map(
                (element, index) => {
                  return (
                    <Typography
                      key={index}
                      variant='h6'
                      style={{ marginBottom: 2 }}
                    >
                      Zadanie: {index + 1}:{' '}
                      {this.props.user.userResponses.userresponses[index]}
                    </Typography>
                  )
                }
              )}

            {this.props.user.userResponses.userresponses !== null && (
              <Grid container spacing={4} style={{ flexDirection: 'column' }}>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginTop: 20 }}
                    id='points'
                    onChange={this.handleChange}
                    label='Punkty za test'
                    type='number'
                    value={this.state.points}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Button
                  variant='contained'
                  disableElevation
                  onClick={() => {
                    this.setPoints()
                  }}
                  style={{
                    backgroundColor: '#25017C',
                    color: '#fff',
                    borderRadius: 20,
                    borderWidth: 2,
                    marginTop: 30,
                    alignSelf: 'flex-start',
                    marginLeft: 16
                  }}
                >
                  Oceń test
                </Button>
              </Grid>
            )}
      {this.props.user.userData.user.points !== 0 && (
              <div style={{ marginTop: 30 }}>
                <TextField
                  style={{ marginTop: 20 }}
                  id='message'
                  onChange={this.handleChange}
                  label='Wiadomość do użytkownika'
                  value={this.state.message}
                  fullWidth
                  variant='outlined'
                  multiline={true}
                  rows={4}
                />
                <Button
                  variant='contained'
                  disableElevation
                  disabled={this.state.message === '' ? true : false}
                  onClick={() => {
                    this.setStatus('accepted')
                  }}
                  style={{
                    backgroundColor: '#98eac7',
                    color: '#fff',
                    borderRadius: 20,
                    borderWidth: 2,
                    marginTop: 30,
                    alignSelf: 'flex-start',
                    marginLeft: 0
                  }}
                >
                  Przyjęty
                </Button>{' '}
                <Button
                  variant='contained'
                  disableElevation
                  disabled={this.state.message === '' ? true : false}
                  onClick={() => {
                    this.setStatus('rejected')
                  }}
                  style={{
                    backgroundColor: '#f22565',
                    color: '#fff',
                    borderRadius: 20,
                    borderWidth: 2,
                    marginTop: 30,
                    alignSelf: 'flex-start',
                    marginLeft: 16
                  }}
                >
                  Odrzucony
                </Button>
              </div>
            )}
          </div>
        )}
        {this.props.user === undefined && (
          <div>
            <div className='form-container-top-bar-adm'>
              <div className='form-container-top-bar-left-adm'>
                Wybierz użytkownika.
              </div>
              <div className='form-container-top-bar-right-adm' />
            </div>
            <div className='waiting-img' />
          </div>
        )}
      </div>
    )
  }
}

StepAdm.propTypes = {
  user: PropTypes.object.isRequired
}

export default withApollo(StepAdm)
