import React, { Component } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
export default class Step extends Component {
  render() {
    return (
      <div
        className={
          this.props.isActive === this.props.id
            ? "step-container-active"
            : "step-container"
        }
        onClick={this.props.onClick}
      >
        <p className="step-num">
          {this.props.stepNumber}{" "}
          {this.props.id <= this.props.completed &&
            this.props.isActive !== this.props.id && (
              <CheckCircleIcon
                style={{ color: "green", height: 20, width: 20 }}
              />
            )}
        </p>
        <p className="step-desc">{this.props.stepDesc}</p>
      </div>
    );
  }
}
