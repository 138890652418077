import gql from "graphql-tag";

export const ACTIVATE_MUTATION = gql`
  mutation activateUser($activationKey: String!) {
    activateUser(activationKey: $activationKey) {
      __typename
    }
  }
`;

export const NEW_LINK_MUTATION = gql`
  mutation sendActivationLink($email: String!) {
    sendActivationLink(email: $email) {
      __typename
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

export const SEND_EMAIL_MUTATION = gql`
  mutation userSendReset($email: String!) {
    userSendReset(email: $email) {
      __typename
    }
  }
`;

export const RESET_MUTATION = gql`
  mutation userReset($newPassword: String!, $passResetKey: String!) {
    userReset(newPassword: $newPassword, passResetKey: $passResetKey) {
      __typename
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $name: String!
    $surname: String!
  ) {
    createUser(
      email: $email
      password: $password
      name: $name
      surname: $surname
    ) {
      user {
        id
      }
    }
  }
`;

export const PERSONAL_DATA_MUTATION = gql`
  mutation appendUser(
    $firstName: String
    $address: String!
    $address2: String
    $birthcity: String!
    $birthdate: String!
    $city: String!
    $city2: String
    $inter1: String
    $inter2: String
    $p1address: String
    $p1city: String
    $p1email: String
    $p1name: String
    $p1phone: String
    $p1workplace: String
    $p2address: String
    $p2city: String
    $p2email: String
    $p2name: String
    $p2phone: String
    $p2workplace: String
    $pesel: String!
    $postal: String!
    $postal2: String
    $p1job: String
    $p2job: String
    $country2: String!
    $country: String!
    $achievments: String
    $location: String
  ) {
    appendUser(
      firstName: $firstName
      address: $address
      address2: $address2
      birthcity: $birthcity
      birthdate: $birthdate
      city: $city
      city2: $city2
      inter1: $inter1
      inter2: $inter2
      p1address: $p1address
      p1city: $p1city
      p1email: $p1email
      p1name: $p1name
      p1phone: $p1phone
      p1workplace: $p1workplace
      p2address: $p2address
      p2city: $p2city
      p2email: $p2email
      p2name: $p2name
      p2phone: $p2phone
      p2workplace: $p2workplace
      pesel: $pesel
      postal: $postal
      postal2: $postal2
      country: $country
      country2: $country2
      p1job: $p1job
      p2job: $p2job
      achievments: $achievments
      location: $location
    ) {
      output
    }
  }
`

export const UPLOAD_FILE_STEP1_MUTATION = gql`
  mutation uploadDoc($fileBase64: String!) {
    uploadDoc(fileBase64: $fileBase64) {
      user {
        id
      }
    }
  }
`

export const SUBMIT_TEST_MUTATION = gql`
  mutation submitResponses(
    $res1: String
    $res2: String
    $res3: String
    $res4: String
    $res5: String
    $res6: String
    $res7: String
    $res8: String
    $res9: String
    $res10: String
    $res11: String
    $res12: String
    $res13: String
    $res14: String
    $res15: String
    $res16: String
    $res17: String
    $res18: String
    $res19: String
    $res20: String
  ) {
    submitResponses(
      res1: $res1
      res2: $res2
      res3: $res3
      res4: $res4
      res5: $res5
      res6: $res6
      res7: $res7
      res8: $res8
      res9: $res9
      res10: $res10
      res11: $res11
      res12: $res12
      res13: $res13
      res14: $res14
      res15: $res15
      res16: $res16
      res17: $res17
      res18: $res18
      res19: $res19
      res20: $res20
    ) {
      me {
        activeStep
      }
    }
  }
`;

export const UPLOAD_FILE_STEP3_MUTATION = gql`
  mutation uploadCompressed($fileBase64: String!) {
    uploadCompressed(fileBase64: $fileBase64) {
      user {
        id
      }
    }
  }
`

export const SET_POINTS = gql`
  mutation setPoints($id: ID!, $points: Int!) {
    setPoints(id: $id, points: $points) {
      user {
        points
      }
    }
  }
`
export const SET_STATUS = gql`
  mutation userAccept(
    $id: ID!
    $accepted: Boolean!
    $message: String!
    $wra: String!
  ) {
    userAccept(id: $id, accepted: $accepted, message: $message, wra: $wra) {
      user {
        message
      }
    }
  }
`