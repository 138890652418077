import React, { Component } from "react";
import "../styles/landing.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import WhiteLogo from "../images/svg/whiteLogo";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Slideshow from "../components/swiperComp";
import MovieSlideshow from "../components/swiperMovies";
import YouTubeGrid from "react-youtube-grid"

import SimpeExpansionPanel from "../components/expansionPanel";
import history from "../utils/history";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import YouTube from "react-youtube";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile } from '@fortawesome/free-solid-svg-icons'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faSchool } from '@fortawesome/free-solid-svg-icons'
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'


import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

import Microsoft from "../images/partners/microsoft.png";
import WSB from "../images/partners/wsb.png";
import Techni from "../images/partners/Techni_Ventures_logo.svg";
import syscrew from "../images/partners/SYScrew_black.svg";
import plum from "../images/partners/PlumResearch_black_RGB.svg";
import YouNeedIt from "../images/partners/youneedit.svg";
import Capful from "../images/partners/capful.png";

import Kulon from "../images/council/Kulon.jpg";
import Kozlowski from "../images/council/kozlowski.jpg";
import Zduniak from "../images/council/zduniak.jpg";
import Stempniewicz from "../images/council/tytus.png";
import Ksiezopolski from "../images/council/ksiezopolski.jpg";
import Czech from "../images/council/czech.jpg";
import Lobejko from "../images/council/lobejko.jpg";
// import Gil from "../images/council/Itay.jpg";
import Paszkiewicz from "../images/council/paszkiewicz.jpg";
import Sobotka from "../images/council/sobotka.jpeg";
import Byszewski from "../images/council/byszewski.png";
import Czuprynski from "../images/council/czuprynski.png";
import Alonso from "../images/council/cristobal.png";

import Stochmal from "../images/teachers/as.jpeg";
import Zieniuk from "../images/teachers/bz.jpg";
import Karpinska from "../images/teachers/jk.jpg";
import Klosiewicz from "../images/teachers/ok.png";
import Smolenski from "../images/teachers/pw.jpg";
import Jasinski from "../images/teachers/bj.png";
import Wieczorek from "../images/teachers/jw.png";


import ProgramImage from "../images/landing/program.jpg";
import KadraImage from "../images/landing/kadra.jpg";
import PracaImage from "../images/landing/praca.jpg";

import Select from "react-dropdown-select";


const options = [
  { value: 'Warszawa', label: 'Warszawa' },
  { value: 'Lublin', label: 'Lublin' },
  { value: 'Poznań', label: 'Poznań' },
  { value: 'Wrocław', label: 'Wrocław' },
  { value: 'Trójmiasto', label: 'Trójmiasto' },
  { value: 'Kraków', label: 'Kraków' },
  { value: 'Rzeszów', label: 'Rzeszów' },
  { value: 'Pruszków', label: 'Pruszków' }
]

const styles = (theme) => ({
  root: {
    "& .MuiAppBar-root  ": {
      backgroundColor: "red",
    },
    "& MuiTypography-root  ": {
      fontFamily: "Montserrat",
    },
    "& .MuiButton-root	": {
      marginTop: 40,
      borderRadius: 50,
      textTransform: "none",
      fontSize: 26,
      fontWeight: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 8,
      paddingBottom: 8,
      backgroundColor: "#F7AA27",
    },
  },
  buttonOne: {
    marginTop: 40,
    borderRadius: 50,
    textTransform: "none",
    fontSize: 26,
    fontWeight: 10,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#F7AA27",
  },
  buttonRight: {
    marginTop: 40,
    borderRadius: 50,
    fontSize: 16,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#F7AA27",
    alignSelf: "flex-end",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  buttonLocation: {
    // marginTop: 40,
    borderRadius: 50,
    fontSize: 12,
    // paddingLeft: 40,
    // paddingRight: 40,
    // paddingTop: 8,
    // paddingBottom: 8,
    backgroundColor: "#F7AA27",
    alignSelf: "flex-end",
    fontFamily: "Montserrat",
    fontWeight: 500,
    display: 'inline-block',

  },
  buttonLessons: {
    marginTop: 40,
    borderRadius: 50,
    textTransform: "none",
    fontSize: 20,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#F7AA27",
    alignSelf: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  barN: {
    backgroundColor: "transparent",
  },
  barA: {
    backgroundColor: "#280086",
  },
  buttonsNav: {
    fontFamily: "Montserrat",
  },
  buttonNavYellow: {
    fontFamily: "Montserrat",
    color: "rgba(247, 170, 39, 1)",
  },
});

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.s0 = React.createRef();
    this.s1 = React.createRef();
    this.s2 = React.createRef();
    this.s3 = React.createRef();
    this.s4 = React.createRef();
    this.s5 = React.createRef();
    this.s6 = React.createRef();
    this.s7 = React.createRef();
    this.s8 = React.createRef();
    this.s9 = React.createRef();
    this.s10 = React.createRef();
    this.s11 = React.createRef();
  }
  state = {
    scrolled: false,
    price: 1990,
    location: "Warszawa, Okopowa 59"
  };



 

  // changePrice = param => e => {
  //   alert("YOYO")
  //   this.price.value = param;
  // }

  clickLocation = (city) => {
    console.log(city);
    switch(city){
      case "Warszawa":
        this.clickWarszawa();
        break;
      case "Lublin":
        this.clickLublin();
          break;
      case "Kraków":
        this.clickKrakow();
        break;
      case "Poznań":
        this.clickPoznan();
        break;
      case "Wrocław":
        this.clickWroclaw();
        break;
      case "Trójmiasto":
        this.clickGdansk();
        break;
      case "Rzeszów":
        this.clickRzeszow();
        break;
      case "Pruszków":
        this.clickPruszkow();
        break;
        
    }
  }

  clickLublin = () => {
    this.setState({'price': 1490, "location": "Lublin, Gęsia 5"});
  }

  clickWarszawa = () => {
    this.setState({'price': 1990, "location": "Warszawa, Okopowa 59"});
  }

  clickPoznan = () => {
    this.setState({'price': 1990, "location": "Poznań, Strusia 12"});
  }

  clickWroclaw = () => {
    this.setState({'price': 1990, "location": "Wrocław, Legnicka 56"});
  }

  clickRzeszow = () => {
    this.setState({'price': 1490, "location": "Rzeszów"});
  }

  clickPruszkow = () => {
    this.setState({'price': 1990, "location": "Pruszków"});
  }

  clickKrakow = () => {
    this.setState({'price': 1990, "location": "Kraków"});
  }

  clickGdansk = () => {
    this.setState({'price': 1990, "location": "Gdańsk, Lęborska 3"});
  }
  
  changePriceExpensive = ()  => {
    this.setState({'price': 1990});
  }

  changePriceCheap = ()  => {
    this.setState({'price': 1500});
  }

  
  scrollToMyRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 110);

  tryScroll = () => {
    const { params } = this.props.match;
    if (params.id !== undefined) {
      switch (params.id) {
        case "about": {
          this.scrollToMyRef(this.s1);
          break;
        }
        case "program": {
          this.scrollToMyRef(this.s2);
          break;
        }
        case "kadra": {
          this.scrollToMyRef(this.s3);
          break;
        }
        case "lokalizacje": {
          this.scrollToMyRef(this.s4);
          break;
        }
        case "rekrutacja": {
          this.scrollToMyRef(this.s5);
          break;
        }
        case "darmowe": {
          this.scrollToMyRef(this.s6);
          break;
        }
        case "rada": {
          this.scrollToMyRef(this.s7);
          break;
        }
        case "rekomendacje": {
          this.scrollToMyRef(this.s8);
          break;
        }
        case "patroni": {
          this.scrollToMyRef(this.s9);
          break;
        }
        case "faq": {
          this.scrollToMyRef(this.s10);
          break;
        }
        case "kontakt": {
          this.scrollToMyRef(this.s11);
          break;
        }
        default:
          history.push("/404");
          break;
      }
    }
  };

  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.tryScroll();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.state.scrolled === false && window.pageYOffset > 100) {
      this.setState({ scrolled: true });
    }
    else if(this.state.scrolled === true && window.pageYOffset == 0)
    {
      this.setState({ scrolled: false });
    }

  };

  render() {
    const { classes } = this.props;
    const opts = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };
    return (
      <div style={{ width: "100%" }}>
        {/* HERE DELETED APP BAR */}
        <div className="section-one" ref={this.s0}>
        <div className="app-bar">
          <AppBar
            position="fixed"
            className={
              this.state.scrolled === true ? classes.barA : classes.barN
            }
            elevation={0}
          >
            <Toolbar>
              <WhiteLogo
                className="top-logo"
                onClick={() => {
                  this.scrollToMyRef(this.s0);
                }}
              />

              <div style={{ flexGrow: 1 }} />
              <div className="buttons">
                <div
                  className="menu-button-container"
                  onClick={() => {
                    this.scrollToMyRef(this.s1);
                    history.push("/");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonsNav}>
                    O&nbsp;szkole
                  </Typography>
                </div>
                <div
                  className="menu-button-container"
                  onClick={() => {
                    this.scrollToMyRef(this.s2);
                    history.push("/section/about");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonsNav}>
                    Program
                  </Typography>
                </div>{" "}
                <div
                  className="menu-button-container"
                  onClick={() => {
                    this.scrollToMyRef(this.s3);
                    history.push("/section/program");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonsNav}>
                    Kadra
                  </Typography>
                </div>{" "}
                <div
                  className="menu-button-container"
                  onClick={() => {
                    this.scrollToMyRef(this.s4);
                    history.push("/section/kadra");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonsNav}>
                    Lokalizacje
                  </Typography>
                </div>{" "}
                <div
                  className="menu-button-container"
                  onClick={() => {
                    this.scrollToMyRef(this.s5);
                    history.push("/section/lokalizacje");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonsNav}>
                    Rekrutacja
                  </Typography>
                </div>{" "}
           
                <div
                  className="menu-button-container"
                  onClick={() => {
                    this.scrollToMyRef(this.s10);
                    history.push("/section/faq");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonsNav}>
                    FAQ
                  </Typography>
                </div>{" "}
                <div
                  className="menu-button-container"
                  onClick={() => {
                    this.scrollToMyRef(this.s11);
                    history.push("/section/kontakt");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonsNav}>
                    Kontakt
                  </Typography>
                </div>{" "}
                <div
                  className="menu-button-container"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <Typography variant="h6" className={classes.buttonNavYellow}>
                    Zaloguj
                  </Typography>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </div>

          <div className="left-panel">
            <p className="big-text">Niepubliczne Technikum Programistyczne</p>
            <p className="small-text">
              Opanuj programowanie i zdobądź pracę <br />jeszcze przed studiami!
            </p>
            <p className="small-location-text">Warszawa, Kraków, Poznań, Wrocław, Gdańsk, Lublin, Rzeszów, Pruszków</p>
            <div className="button-mobile">
              <Button
                className={classes.buttonOne}
                variant="contained"
                style={{ color: "#fff" }}
                disableElevation
                onClick={() => {
                  this.scrollToMyRef(this.s1);
                }}
              >
                Dowiedz się więcej
              </Button>
            </div>
          </div>
          {/* <div className="right-panel" /> */}
        </div>


       







        <div className="section-two" ref={this.s1}>
          <p className="section-two-title">Dlaczego Techni Schools?</p>
          <div className="section-two-main">
            <div className="section-two-left">
              <img src={ProgramImage} className="section-two-images" />
            </div>
      
            <div className="section-two-right">
              <p className="section-two-purple-element">
                <div className="section-two-tile-content">
                  <p className="section-two-column-title">
                    <FontAwesomeIcon icon={faGraduationCap} color="#5300fe" marginLeft="5%" />
                    <br />PROGRAM NAUCZANIA
                  </p>
                  <div className="section-two-column-content">
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        2600 godzin nauczania przedmiotów zawodowych co daje <b>800 godzin więcej niż w innych szkołach</b>
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Trzy przedmioty w zakresie rozszerzonym:<br /><b>matematyka, fizyka i język angielski</b>
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Rozszerzona podstawa programowa o: <b>cyberbezpieczeństwo, sztuczna inteligencja, programowanie gier</b>
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                       Przygotowanie do konkursów, <b>olimpiad</b> i&nbsp;egzaminów zawodowych    
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Trzeci język obcy - <b>język chiński</b>
                    </p>

                  </div>
                </div>
              </p>
            </div>
          </div>

          

          <div className="section-two-main">
            <div className="section-two-left-text">
              <p className="section-two-purple-element">
                  <p className="section-two-column-title">
                    <FontAwesomeIcon icon={faSchool} color="#5300fe" marginRight="5%" />
                    <br />KADRA
                  </p>
                  <div className="section-two-column-content">
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Przedmiotów ogólnokształcących nauczają <b>nauczyciele</b> i&nbsp;<b>wykładowcy</b> uczelni wyższych z <b>doświadczeniem pedagogicznym</b>
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Przedmiotów zawodowych uczą <b>praktycy rynkowi</b> - programiści z wieloletnim doświadczeniem pracy w branży
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Zajęcia laboratoryjne są realizowane w grupach <b>16-osobowych</b>, aby nauczyciel mógł się skupić indywidualnie na uczniu
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                      Wiemy, że praca przy komputerze to nie wszystko, dlatego stawiamy też na urozmaicenie zajęć wychowania fizycznego o&nbsp;<b>sztuki walki i&nbsp;dietę</b>
                    </p>

       
                  </div>
                </p>
            
            </div>
      
            <div className="section-two-right-image">
            <img src={KadraImage} className="section-two-images" />

  
            </div>
          </div>

          <div className="section-two-main">
            <div className="section-two-left">
              <img src={PracaImage} className="section-two-images" />
            </div>
      
            <div className="section-two-right">
              <p className="section-two-purple-element">
                  <p className="section-two-column-title">
                    <FontAwesomeIcon icon={faLaptopCode} color="#5300fe" marginLeft="5%" />
                    <br />PIERWSZA PRACA
                  </p>
                  <div className="section-two-column-content">
                    <p className="section-three-list-element">
                        <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Nie musisz czekać na studia - <b>programowania nauczysz się już w technikum</b>
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Pierwsza <b>praca</b> jeszcze <b>przed 18-tką</b> - w trakcie edukacji zdobędziesz doświadczenie, aby po szkole pracować jako <b>junior programista</b>
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                      Praktyki i <b>płatne staże</b> w trakcie trwania nauki
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                      Praktyki i staże realizowane są w <b>startupach</b> technologicznych i <b>firmach</b> informatycznych
                    </p>
                    <p className="section-three-list-element">
                      <FontAwesomeIcon icon={faCaretRight} color="#5300fe"/>&nbsp;
                        Zajęcia zawodowe prowadzimy w oparciu o&nbsp;nowoczesne narzędzia <b><a className="tools" href="https://www.cyberskilleracademy.com/pl/">Cyberskiller</a> i <a className="tools" href="https://pixblocks.com/">Pixblocks</a></b>
                    </p>

                  </div>
                </p>
            </div>
          </div>





        </div>

        <div className="contact">          
            <div className="contact-container">
            <p className="contact-text">
              <a href="tel:+48723809192"> 
                <FontAwesomeIcon icon={faPhoneVolume} color="#280086" />
                 &nbsp;723 809 192 
              </a>
            </p>
            <p className="contact-text">
              <a href="mailto:kontakt@technischools.com">
                <FontAwesomeIcon icon={faEnvelope} color="#280086" />
                &nbsp;kontakt@technischools.com
              </a>
            </p>
            </div>
          {/* </p> */}
        </div>

        <div className="section-three" ref={this.s2}>
          <p className="section-three-title">Technik programista</p>
          <div className="section-three-container">
            <div className="offer-left-panel">
              <div className="section-three-upper-text">
                {/* <p className="section-three-purple-title">TECHNIK PROGRAMISTA</p> */}
                {/* <br /> */}
                <p className="section-three-purple-element">
                Przygotujemy naszych uczniów zarówno do matury, jak i łatwego wejścia na rynek pracy zaraz po jej zdaniu. Skupiamy się na praktyce i umiejętnościach, które nie tylko teraz są pożądane wśród programistów, ale i tych, które będą w przyszłości. 
                </p>
                
                {/* <p className="section-three-list-element">
                  INF.03. Tworzenie i administrowanie stronami i aplikacjami internetowymi oraz bazami danych.
                </p>
                <p className="section-three-list-element">
                  INF.04. Projektowanie, programowanie i testowanie aplikacji.
                </p> */}
              </div>
              <br />
              <div className="section-three-upper-text">
                <p className="section-three-purple-title"></p>
                
                <p className="section-three-purple-element">
                  Już w 5-tej klasie będziesz mógł pracować jako:
                </p>

                <p className="section-three-list-element">
                  programista web aplikacji
                </p>
                <p className="section-three-list-element">
                  programista gier
                </p>
                <p className="section-three-list-element">
                  programista aplikacji mobilnych
                </p>
                <p className="section-three-list-element">
                  programista sztucznej inteligencji
                </p>
                <p className="section-three-list-element">
                  programista baz danych / business intelligent analyst
                </p>
                <p className="section-three-list-element">
                  programista C++
                </p>
                <p className="section-three-list-element">
                  devops enginner
                </p>
                <p className="section-three-list-element">
                  tester aplikacji
                </p>
              
                
              </div>

            </div>
            <div className="offer-right-panel">
              <div className="offer-image-container" />
              <div className="button-mobile">
                      <Button
                        className={classes.buttonRight}
                        variant="contained"
                        style={{ color: "#fff" }}
                        disableElevation
                        href="/files/PlanNauczania.pdf"
                      >
                        Czujesz, że to coś dla Ciebie? <br />Zobacz program nauczania!
                      </Button>
                </div>
            </div>
          </div>
        </div>

        <div className="section-council" ref={this.s3}>
          <p className="section-patrons-title">Kadra pedagogiczna</p>
          <div className="teacher-container">
            <div
              className="item-council"
            >
              <div
                style={{
                  backgroundImage: `url(${Kozlowski})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  <p className="item-council-text">
                    {" "}
                    Mateusz Kozlowski
                    <br />
                    Cyberbezpieczeństwo, Programowanie, Sztuczna inteligencja
                  </p>
                </div>
              </div>
            </div>
            <div
              className="item-council"
            >
              <div
                style={{
                  backgroundImage: `url(${Klosiewicz})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  <p className="item-council-text">
                    {" "}
                    dr Olga Kłosiewicz
                    <br />
                    Filozofia, etyka
                  </p>
                </div>
              </div>
            </div>
            <div
              className="item-council"
            >
              <div
                style={{
                  backgroundImage: `url(${Jasinski})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  <p className="item-council-text">
                    {" "}
                    Bartłomiej Jasiński
                    <br />
                    Programowanie stron internetowych
                  </p>
                </div>
              </div>
            </div>
            <div
              className="item-council"
            >
              <div
                style={{
                  backgroundImage: `url(${Karpinska})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Justyna Karpińska
                    <br />
                    Grafika komputerowa
                  </p>
                </div>
              </div>
            </div>
            <div
              className="item-council"
            >
              <div
                style={{
                  backgroundImage: `url(${Smolenski})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Paweł Smoleński
                    <br />
                    Bazy danych, programowanie
                  </p>
                </div>
              </div>
            </div>
            <div
              className="item-council"
              href=""
            >
              <div
                style={{
                  backgroundImage: `url(${Wieczorek})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Jacek Wieczorek
                    <br />
                    Wychowanie fizyczne, zapasy
                  </p>
                </div>
              </div>
            </div>
            <div
              className="item-council"
              href=""
            >
              <div
                style={{
                  backgroundImage: `url(${Stochmal})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Anetta Stochmal
                    <br />
                    Język angielski
                  </p>
                </div>
              </div>
            </div>
            <div
              className="item-council"
              href=""
            >
              <div
                style={{
                  backgroundImage: `url(${Zieniuk})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Bartłomiej Zieniuk
                    <br />
                    Chemia
                  </p>
                </div>
              </div>
            </div>

       
            <p className="right-description">i wielu innych...</p>


          </div>


        </div>
      
        <div className="section-map" ref={this.s4}>
          <p className="section-map-title">Lokalizacje</p>
   
          
          <div className="lower-map-container">
            {/* <div className="locations-container">
              <div className="location-address" onClick={this.clickWarszawa}>
                <div className="left-location">
                  <p className="map-city">Warszawa</p>
                  <p className="map-address">Okopowa 59</p>
                </div>
                <img className="location-image" src={Warszawa} />
              </div>
              <div className="location-address" onClick={this.clickLublin}>
                <div className="left-location">
                  <p className="map-city">Lublin</p>
                  <p className="map-address">Chodźki 10a</p>
                </div>
                <img className="location-image" src={Lublin} />
              </div>
              <div className="location-address" onClick={this.clickPoznan}>
                <div className="left-location">
                  <p className="map-city">Poznań</p>
                  <p className="map-address">Strusia 12</p>
                </div>
                <img className="location-image" src={Poznan} />
                                

              </div>
              <div className="location-address" onClick={this.clickWroclaw}>
                <div className="left-location">
                  <p className="map-city">Wrocław</p>
                  <p className="map-address">Legnicka 56</p>
                </div>
                <img className="location-image" src={Wroclaw} />
              </div>
              <div className="location-address" onClick={this.clickGdansk}> 
                <div className="left-location">
                  <p className="map-city">Gdańsk</p>
                  <p className="map-address">Zakopiańska 37A</p>
                </div>
                <img className="location-image" src={Gdansk} />
              </div>
              <div className="location-address" onClick={this.clickKrakow}>
                <div className="left-location">
                  <p className="map-city">Kraków</p>
                  <p className="map-address"></p>
                </div>
                <img className="location-image" src={Krakow} />
              </div>
              <div className="location-address" onClick={this.clickRzeszow}>
                <div className="left-location">
                  <p className="map-city">Rzeszów</p>
                  <p className="map-address"></p>
                </div>
                <img className="location-image" src={Rzeszow} />
              </div>
              <div className="location-address" onClick={this.clickPruszkow}> 
                <div className="left-location">
                  <p className="map-city">Pruszków</p>
                  <p className="map-address"></p>
                </div>
                <img className="location-image" src={Pruszkow} />
              </div>
            </div> */}

          <div className="section-two-main">
            {/* <div className="section-two-left">
              <img src={PracaImage} className="section-two-images" />
            </div> */}
      
            <div className="section-location-description">
              {/* <center> */}
              <Select options={options} className="location-dropdown" placeholder="Wybierz miasto" onChange={(values) => this.clickLocation(values[0].value)}/>

              <p className="section-three-list-element">

              <b>Lokalizacja: {this.state.location}</b></p><br />
              {/* </center> */}
              <p className="section-three-list-element"><b>Opłaty:</b><br />
              Szkoły publiczne niejednokrotnie zatrudniają pierwszego wolnego nauczyciela z okolicy. 
              Nasze priorytety są inne - wybraliśmy najlepszych specjalistów w Polsce, którzy mają ogromną wiedzę
              i pasję do nauczania. Nie oszczędzamy na kadrze, bo przyszłość uczniów jest najważniejsza.
              </p>

              <p className="section-three-list-element">Czesne: {this.state.price}zł miesięcznie</p>
              <p className="section-three-list-element">Opłata rekrutacyjna: {this.state.price}zł, wnoszona po podpisaniu umowy.</p>
              <br />
              <p className="section-three-list-element"><b>Mało czy dużo?</b>&nbsp;To mniej niż leasing samochodu czy wynagrodzenie, na jakie może liczyć junior programista po skończeniu naszego technikum. I&nbsp;nawet 10 razy mniej niż zarobki programisty z&nbsp;kilkuletnim doświadczeniem. To po prostu opłacalna inwestycja w przyszłość Twojego dziecka.</p>
              <br />
              <p className="section-three-list-element"><b>W cenie wliczone są</b>:
              <li> laptop, na którym uczeń może pracować przez cały okres trwania edukacji zarówno w szkole, jak&nbsp;i&nbsp;w&nbsp;domu</li>
              <li>pakiet narzędzi i programów do tworzenia oprogramowania</li>
              <li>materiały dydaktyczne służące do nauki zawodu, w tym nagrania z lekcji</li>
              <li>dodatkowe zajęcia z mentorami technologicznymi – bootcampy służące do pogłębionej nauki specjalistycznych elementów</li>
              <li>dostęp do wydarzeń i meet-upów organizowanych przez grupę Techni</li>
              </p>
              <br />
              <p className="section-three-list-element"><i>Najlepsi uczniowie mogą otrzymać stypendium w&nbsp;wysokości czesnego, które są finansowane przez szkołę oraz patronów szkoły.</i></p>
              <p className="section-three-list-element"><i>Żadnych dodatkowych i&nbsp;ukrytych opłat w trakcie trwania edukacji.</i></p>

            </div>
          {/* </div> */}

            {/* <div className="map-container">
              <p className="section-three-list-element"><b>Lokalizacja: </b>{this.state.location}</p>
              <p className="section-three-list-element"><b>Czesne: </b>{this.state.price}zł</p>
              <p className="section-three-list-element"><b>Opłata rekrutacyjna:</b> {this.state.price}zł, wnoszona po podpisaniu umowy</p>
              <p className="section-three-list-element"><b>W cenie wliczone są</b>:
              <li>indywidualny sprzęt dla każdego ucznia w postaci laptopa, na którym będzie mógł pracować przez cały okres trwania edukacji zarówno w szkole, jak i&nbsp;w&nbsp;domu</li>
              <li>pakiet narzędzi i programów do tworzenia oprogramowania</li>
              <li>materiały dydaktyczne służące do nauki zawodu</li>
              <li>dodatkowe zajęcia – bootcampy, służące do pogłębionej nauki specjalistycznych elementów, które będą prowadzone przez mentorów technologicznych</li>
              </p>
              <p className="section-three-list-element"><i>Najlepsi uczniowie mogą otrzymać stypendium w&nbsp;wysokości czesnego, które są finansowane przez szkołę oraz patronów szkoły</i></p>
              <p className="section-three-list-element"><i>Żadnych dodatkowych i&nbsp;ukrytych opłat w trakcie trwania edukacji.</i></p>
              <p className="section-three-list-element"><b>Mało czy dużo?</b>&nbsp;To mniej niż leasing samochodu czy wynagrodzenie, na jakie może liczyć junior programista po skończeniu naszego technikum. I&nbsp;nawet 10 razy mniej niż zarobki programisty z&nbsp;kilkuletnim doświadczeniem. To po prostu opłacalna inwestycja w przyszłość Twojego dziecka.</p>

            </div> */}
          </div>
          </div>

        </div>

        <div className="section-steps" ref={this.s5}>
          <p className="section-three-title">Rekrutacja</p>
          <div className="wide-steps" />
          <div className="info-and-button">
            <div className="left-info">
              <p>
                Rekrutacja do Technikum trwa do 30.04.2021
                <br />
                <br />
                Informacje o cząstkowych wynikach rekrutacji będą przesyłane na
                bieżąco.
              </p>
              <p className="bold-left-text">
                Ilość miejsc w roczniku 2021/2022 - 32!
              </p>
            </div>
            <div className="button-join-container">
              <Button
                className={classes.buttonRight}
                variant="contained"
                style={{ color: "#fff" }}
                onClick={() => {
                  history.push("/register");
                }}
              >
                załóż konto
              </Button>
            </div>
          </div>
        </div>

        <div className="section-steps" ref={this.s6}>
          <p className="section-three-title">Lekcje online</p>
          <br />
          <p className="section-three-subtitle">Zobacz przykłady realizowanych przez nas zajęć dla ósmoklasistów. Zapraszamy na nasz kanał youtube!</p>
          <div class="youtube-techni-gallery">
            <YouTubeGrid youtubeUrls={["zfZ_r5bBxt0","8El7cU70R4M","jLR7ERthktQ"]} />
            {/* <YouTubeGrid youtubeUrls={["mdu_t5ya_F8","t5LqJ1q1G_k","t5LqJ1q1G_k"]} /> */}
          </div>

            <div className="button-mobile">
                <Button
                  className={classes.buttonRight}
                  variant="contained"
                  style={{ color: "#fff" }}
                  disableElevation
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/channel/UCfIthg-_i_ONHwlLcKvanZQ",
                      '_blank' // <- opens the link in a new tab or window.
                    );
                  }}
                >
                  zobacz więcej na kanale youtube
                </Button>
              </div>
        </div>




        <div className="section-council" ref={this.s7}>
          <p className="section-patrons-title">Rada Naukowa Szkoły</p>
          <div className="council-container">
            <a
              className="item-council"
              href="https://www.linkedin.com/in/matikozlowski/"
            >
              <div
                style={{
                  backgroundImage: `url(${Kozlowski})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  <p className="item-council-text">
                    {" "}
                    Mateusz Kozlowski
                    <br />
                    CEO Techni Schools
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="http://sokrates.gda.pl/nasz-zespol/joanna-kulon/"
            >
              <div
                style={{
                  backgroundImage: `url(${Kulon})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  <p className="item-council-text">
                    {" "}
                    Joanna Kulon
                    <br />
                    Dyrektor Szkoły i Przedszkola
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.wsb.net.pl/wladze-wsb/andrzej-zduniak/"
            >
              <div
                style={{
                  backgroundImage: `url(${Zduniak})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  <p className="item-council-text">
                    {" "}
                    dr Andrzej Zduniak
                    <br />
                    Rektor Wyszej Szkoły Bezpieczeństwa
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/tytus-stempniewicz-109a4717/"
            >
              <div
                style={{
                  backgroundImage: `url(${Stempniewicz})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Tytus Stempniewicz
                    <br />
                    Partner w Techni Ventures
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/bogdan-ksiezopolski-prof/"
            >
              <div
                style={{
                  backgroundImage: `url(${Ksiezopolski})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    dr hab. Bogdan Księżopolski
                    <br />
                    prof. UMCS, Dyrektor Instytutu Informatyki
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/rafalczuprynski/"
            >
              <div
                style={{
                  backgroundImage: `url(${Czuprynski})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Rafał Czupryński
                    <br />
                    Lider Ewangelizacji Deweloperów w Europie Środkowej i
                    Wschodniej w Microsoft
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.microsoft.com/pl-pl/education"
            >
              <div
                style={{
                  backgroundImage: `url(${Czech})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Paweł Czech
                    <br />
                    Współzałożyciel i wice-prezes NUADU
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/mariusz-%C5%82obejko-370a0727/"
            >
              <div
                style={{
                  backgroundImage: `url(${Lobejko})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Mariusz Łobejko
                    <br />
                    Product Portfolio Director
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/ssobot/"
            >
              <div
                style={{
                  backgroundImage: `url(${Sobotka})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Sławomir Sobótka
                    <br />
                    Właściciel Bottega IT Minds
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/lucjanpaszkiewicz/"
            >
              <div
                style={{
                  backgroundImage: `url(${Paszkiewicz})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Lucjan Paszkiewicz
                    <br />
                    Senior Partner Capful Polska
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/marek-byszewski/"
            >
              <div
                style={{
                  backgroundImage: `url(${Byszewski})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Marek Byszewski
                    <br />
                    Współzałożyciel i CTO YouNeedIT
                  </p>
                </div>
              </div>
            </a>
            <a
              className="item-council"
              href="https://www.linkedin.com/in/cristobalalonso/"
            >
              <div
                style={{
                  backgroundImage: `url(${Alonso})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="item-council-label">
                  {" "}
                  <p className="item-council-text">
                    {" "}
                    Cristobal Alonso
                    <br />
                    Prezes zarządu Startup Wise Guys
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div ref={this.s8}>
          <Slideshow />
        </div>

        <div className="section-patrons" ref={this.s9}>
          <p className="section-patrons-title">Patroni</p>
          <div className="patrons-container">
            <a
              className="item"
              href="https://www.microsoft.com/pl-pl/education"
            >
              <div
                style={{
                  backgroundImage: `url(${Microsoft})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a>
            {/* <a className="item" href="http://okopowa.edu.pl/">
              <div
                style={{
                  backgroundImage: `url(${vizja})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a> */}
            <a className="item" href="http://techniventures.com/">
              <div
                style={{
                  backgroundImage: `url(${Techni})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a>
            <a className="item" href="https://www.wsb.net.pl/">
              <div
                style={{
                  backgroundImage: `url(${WSB})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a>
            <a className="item" href="http://syscrew.pl">
              <div
                style={{
                  backgroundImage: `url(${syscrew})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a>
            <a className="item" href="https://plumresearch.com/">
              <div
                style={{
                  backgroundImage: `url(${plum})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a>
            <a className="item" href="https://www.youneedit.co/">
              <div
                style={{
                  backgroundImage: `url(${YouNeedIt})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a>
            <a className="item" href="https://www.capful.fi/pl/">
              <div
                style={{
                  backgroundImage: `url(${Capful})`,
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            </a>
          </div>
        </div>


        <div className="section-four" ref={this.s10}>
          <p className="section-four-title">Jak możemy Ci pomóc?</p>
          <p className="section-four-subtitle">Najczęściej zadawane pytania</p>
          <div className="expansion-container">
            <SimpeExpansionPanel />
          </div>
        </div>

          <div className="contact" ref={this.s11}>          
            <div className="contact-container">
            <p className="contact-text">
              <a href="tel:+48723809192"> 
                <FontAwesomeIcon icon={faPhoneVolume} color="#280086" />
                 &nbsp;723 809 192 
              </a>
            </p>
            <p className="contact-text">
              <a href="mailto:kontakt@technischools.com">
                <FontAwesomeIcon icon={faEnvelope} color="#280086" />
                &nbsp;kontakt@technischools.com
              </a>
            </p>
            </div>
          {/* </p> */}
        </div>


        <div className="fotter">
          <div className="fotter-left-container">
            <WhiteLogo className="fotter-logo" />
            <div className="fotter-activities-container">
              <Link
                className="fotter-activities"
                to="/files/PolitykaPrywatnosci.pdf"
                download
                target="_blank"
              >
                Polityka prywatności
              </Link>
              <Link
                className="fotter-activities"
                to="/files/RegulaminRekrutacji.pdf"
                download
                target="_blank"
              >
                Regulamin rekrutacji
              </Link>
              <Link
                className="fotter-activities"
                to="/files/RegulaminStrony.pdf"
                download
                target="_blank"
              >
                Regulamin strony
              </Link>
              <Link
                className="fotter-activities"
                to="/files/PlanNauczania.pdf"
                download
                target="_blank"
              >
                Plan nauczania
              </Link>
            </div>
          </div>
          <div className="fotter-right-container">
            <a
              href="https://www.facebook.com/technischools"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                flexDirection: "row",
                textDecoration: "none",
              }}
            >
              <FacebookIcon
                style={{ fontSize: 36, fill: "white", marginRight: 10 }}
              />{" "}
              <p className="facebook-link">facebook/technischools/</p>
            </a>
            <a
              href="http://instagram.com/technischools"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                flexDirection: "row",
                textDecoration: "none",
              }}
            >
              <InstagramIcon
                style={{ fontSize: 36, fill: "white", marginRight: 10 }}
              />
              <p className="facebook-link">Instagram</p>
            </a>
            <a
              href="https://www.youtube.com/channel/UCfIthg-_i_ONHwlLcKvanZQ"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                flexDirection: "row",
                textDecoration: "none",
              }}
            >
              <YouTubeIcon
                style={{ fontSize: 36, fill: "white", marginRight: 10 }}
              />
              <p className="facebook-link">Techni Schools</p>
            </a>
            <p className="fotter-address">
              Techni Schools sp z.o.o.
              <br />
              KRS 0000803622
              <br />
              NIP 7123392662
            </p>
          </div>
        </div>
        <CookieConsent
          style={{ backgroundColor: "#280086", color: "#fff" }}
          buttonText="Rozumiem"
          buttonStyle={{
            color: "#fff",
            fontSize: "14px",
            backgroundColor: "green",
            alignSelf: "center",
          }}
        >
          Ta strona wykorzystuje pliki cookies w celu zapewnienia maksymalnej
          wygody w korzystaniu z naszego serwisu. Jeśli nie wyrażasz zgody na
          zapisywanie informacji zawartej w cookies, ustawienia dotyczące plików
          cookies możesz zmienić w swojej przeglądarce.{" "}
          <Link
            to="/files/PolitykaPrywatnosci.pdf"
            style={{ textDecoration: "none", color: "#fff", cursor: "pointer" }}
            download
            target="_blank"
          >
            Dowiedz się więcej
          </Link>
        </CookieConsent>
      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingPage);
