import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import TagManager from 'react-gtm-module'
import ReactGA from "react-ga";

const tagManagerArgs = {
    gtmId: 'GTM-PBNCRJ3'
}
const trackingId = "UA-160799982-1";

ReactGA.initialize(trackingId);
TagManager.initialize(tagManagerArgs)

let csrftoken;
async function getCsrfToken() {
  if (csrftoken) return csrftoken;
  csrftoken = await fetch("https://api.technischools.com/csrf/", {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => (csrftoken = data.csrfToken));
  return await csrftoken;
}

const httpLink = createHttpLink({
  uri: "https://api.technischools.com/graphql/",
  credentials: "include"
});
const authLink = setContext(async (_, { headers }) => {
  const AUTH_TOKEN = "token";
  const csrftoken = await getCsrfToken();
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
      CSRF: csrftoken
    }
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  }
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
  credentials: "include"
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,

  document.getElementById("root")
);

serviceWorker.unregister();
