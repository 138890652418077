import gql from 'graphql-tag'



export const ME_QUERY = gql`
  {
    me {
      activeStep
      name
      isStaff
      isAdmin
      surname
      message
      accepted
      waiRejAcc
    }
  }
`

export const ADM_QUERY = gql`
  {
    users {
      name
      email
      surname
      id
      isAdmin
      active
    }
  }
`
export const USR_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      name
      surname
      email
      activeStep
      id
      points
      location
      active
      waiRejAcc
    }
  }
`

export const DOC_QUERY = gql`
  query getadmindoc($id: ID!) {
    getadmindoc(id: $id)
  }
`

export const COMPRESSED_QUERY = gql`
  query getadmincompressed($id: ID!) {
    getadmincompressed(id: $id)
  }
`

export const USR_RESPONSES = gql`
  query userresponses($id: ID!) {
    userresponses(id: $id)
  }
`