import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import history from "../utils/history";
import { withApollo } from "react-apollo";

import {RESET_MUTATION} from '../mw/mutations'

const styles = (theme) => ({
  root: {
    flex: 7,
    "& .MuiTextField-root": {
      marginBottom: 20,
      width: "80%",
    },
    "& .MuiButton-root	": {
      marginTop: 40,
      borderRadius: 50,
      textTransform: "none",
      fontSize: 18,
      fontWeight: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
});

class NewPasswordForm extends Component {
  state = {
    newPassword: "",
    newPasswordRepeat: "",
    passResetKey: "",
    message: "",
    error: false,
  };
  async componentDidMount() {
    const values = await queryString.parse(this.props.location);
    this.setState({ values, passResetKey: values.key });
  }
  resetPassword = () => {
    const { newPassword, newPasswordRepeat, passResetKey } = this.state;
    this.setState({ error: false, message: "" });

    const password_re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;

    if (newPassword === "" || newPasswordRepeat === "") {
      this.setState({
        error: true,
        message: "Proszę uzupełnić wszystkie pola!",
      });
    } else if (newPassword !== newPasswordRepeat) {
      this.setState({ error: true, message: "Hasła nie są jednakowe!" });
    } else if (password_re.test(newPassword) === false) {
      this.setState({
        error: true,
        message:
          "Hasło musi składać się z przynajmniej ośmiu znaków, jednej cyfry, jednej wielkiej litery, oraz znaku specjalnego!",
      });
    } else if (this.state.error === false && this.state.message === "") {
      this.props.client
        .mutate({
          mutation: RESET_MUTATION,
          variables: {
            newPassword,
            passResetKey,
          },
        })
        .then((data) => {
          this.setState({
            message: "Hasło zostało zmienione pomyślnie.",
            error: false,
          });
          setTimeout(() => {
            history.push("/login");
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            message: "Błędny link do zmiany hasła.",
            error: true,
          });
        });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    const { classes } = this.props;
    return (
      <form className={classes.root} noValidate autoComplete="off">
        {this.state.message === "" && (
          <p className="light-text">Wpisz nowe hasło.</p>
        )}
        {this.state.message !== "" && this.state.error === false && (
          <p className="light-text-green">{this.state.message}</p>
        )}
        {this.state.message !== "" && this.state.error === true && (
          <p className="light-text-red">{this.state.message}</p>
        )}
        <div>
          <TextField
            id="newPassword"
            label="Podaj nowe hasło"
            value={this.state.newPassword}
            onChange={this.onChange}
            type="password"
          />
          <TextField
            id="newPasswordRepeat"
            label="Powtórz nowe hasło"
            value={this.state.newPasswordRepeat}
            onChange={this.onChange}
            type="password"
          />
        </div>

        <Button
          variant="contained"
          style={{ backgroundColor: "#25017C", color: "#fff" }}
          disableElevation
          onClick={() => this.resetPassword()}
        >
          Zmień hasło
        </Button>
      </form>
    );
  }
}
NewPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withApollo(withStyles(styles)(NewPasswordForm));
