import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../styles/landing.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const questions = [
  {
    q: "Czy są u Was jeszcze wolne miejsca? Jak można się do Was zapisać?",
    a:
      "Tak, mamy jeszcze kilka wolnych miejsc. Jeśli zainteresowanie przekroczy założoną liczbę uczniów - rozważymy rozszerzenie rocznika o kolejną klasę. Aby się zapisać, należy się zarejestrować https://technischools.com/login a następnie wypełnić test. Jego wyniki dostępne są w czasie do 24 h.",
  },
  {
    q:
      "Jak to jest z tym porównaniem technikum i liceum? Które z nich jest lepsze?",
    a:
      "Obiegowa opinia wiele lat uznawała licea za szkoły, które mają wyższy poziom i wnoszą w życie uczniów więcej niż program dostępny w technikum. Z biegiem lat edukacja bardzo się zmieniła i dziś wiele placówek o charakterze technikum ma nawet lepszy poziom od programów realizowanych w placówkach licealnych. Jest to wynikiem nie tylko zmniejszonej liczby uczniów (u nas klasy mają nie więcej niż 16 osób, stąd większa atencja nauczyciela wobec ucznia), ale też unikalnym programem, który - tak jak w naszej szkole - prezentuje wiele praktycznych stron standardowych przedmiotów. Z racji prywatnego finansowania placówki tego typu jak nasza stać na kadrę nauczycielską złożoną z wykładowców uczelni wyższych - co też jest nie bez znaczenia.",
  },
  {
    q: "Co wyróżnia Waszą szkołę na tle innych warszawskich szkół?",
    a:
      "My stawiamy przede wszystkim na praktyczne ujęcie kształcenia młodych techników. Wiemy, że możliwość obcowania z doświadczoną kadrą pracowniczą, złożoną z przedstawicieli rozpoznawalnych, często międzynarodowych korporacji, wniesie w ich zawodowe życie zupełnie inną jakość. Inną kwestią jest zwrócenie uwagi na swego rodzaju savoir vivre biznesowy. Nasi uczniowie będą zdobywać też wiedzę w jaki sposób prezentować swoje projekty, jak się ubrać na wystąpienie, a nawet w jaki sposób sięgać po jedzenie na oficjalnych spotkaniach. Paradoksalnie to są bardzo ważne rzeczy, które mogą się kiedyś okazać kluczowe przy wyborze ich do jakiegoś projektu. Kolejnym elementem jest też nacisk na uczenie języków - w naszej szkolem poza językiem angielskim - będzie też angielski stricte z zakresu sformułowań IT jak również język chiński i francuski. W kolejnym roku rozważamy też zapewnienie uczniom języka koreańskiego. Ważnym aspektem jest też kultura fizyczna. U nas zajęcia z WFu - prowadzone zresztą przez antyterrorystę - będą oparte nie tylko na zwykłych ćwiczeniach z programu szkolnego, ale też na elementach samoobrony. Ponadto większość naszej kadry nauczycielskiej złożona jest z wykładowców uczelni wyższych. Jednak największą domeną naszej szkoły są wspomniane wcześniej praktyczne zajęcia - w pierwszym roku z: programowania stron internetowych, budowy baz danych, business intelligence, grafiki komputerowej w oparciu o program Photoshop, podstaw informatyki, architektury systemów komputerowych i content aplikacji. W drugiej klasie Uczniowie poznają logikę, czyli budowę aplikacji internetowych, w trzecim i czwartym roku aplikacje mobilne, a w piątym roku sztuczną inteligencję i cyberbezpieczeństwo. To daje im spektrum możliwości niespotykane nigdzie indziej.",
  },
  {
    q:
      "Czy Wasza szkoła dobrze przygotuje ucznia do studiowania zagranicą lub matury międzynarodowej?",
    a:
      "Zdecydowanie tak. Dzieje się tak nie tylko z uwagi na szczególną dbałość w aspekcie przygotowania uczniów w zakresie znajomości języka (nasi uczniowie poza standardowym ujęciem języka angielskiego, od pierwszego roku mają też niezależnie dodatkowe zajęcia angielskiego z zakresu słownictwa z branży IT, ale też język francuski i język chiński). To, co daje przygotowanie by konkurować na międzynarodowym rynku edukacyjnym to znajomość przedmiotów specjalistycznych w bardzo szerokim ujęciu z kadrą złożoną z najlepszych specjalistów z branży stratupowej / IT.",
  },
  {
    q:
      "Co z uczniami, którzy nie dostali się do “szkoły marzeń” - czy Wasza szkoła może być dla nich alternatywą?",
    a:
      "Sądzimy, że uczniowie, którzy będą uczęszczać do naszej szkoły, szybko przekonają się, że to właśnie my jesteśmy szkołą marzeń, do której szczęśliwie udało im się trafić. Poziom nauczania, doskonała atmosfera, przyjazny, nowoczesny budynek i zajęcia, o których rówieśnicy będą mogli jedynie pomarzyć będzie przyjemnym wyróżnikiem w towarzystwie.",
  },
  {
    q:
      "Co uczniowie muszą umieć, jaką muszą mieć podstawę, żeby aplikować do Waszej szkoły?",
    a:
      "Nie ukrywamy, że aby zdać test należy wykazać się szczególnymi umiejętnościami w zakresie przedmiotów ścisłych. Ktoś, kto miałby deficyty w tym aspekcie, z czasem mógłby się czuć niekomfortowo nie mogąc nadążyć za innymi uczniami. U nas poziom będzie dość wysoki, ale dzięki temu pozostali uczniowie zawsze będą piąć się ku górze - a to w nauczaniu i wspólnym zdobywaniu szkolnych doświadczeń podstawa.",
  },
  {
    q:
      "Jaka jest oferta pozalekcyjna? Czy są jakieś dodatkowe zajęcia, w które młodzi ludzie będą się mogli wspólnie angażować?",
    a:
      "Przede wszystkim nasza oferta lekcyjna będzie na tyle atrakcyjna, że na typowe zajęcia pozalekcyjne będzie można poświęcić nieco mniej czasu. Będziemy chcieli pokazywac naszym uczniom, że informatyka niejedno ma imię - że można pracować w bardzo wielu aspektach tej branży i wiele z nich może być źródłem satysfakcjonującej pracy. Spotkania z fachowcami w swojej dziedzinie będą z pewnością mocną stroną naszego portfolio edukacyjnego.",
  },
  {
    q:
      "Czy to prawda, że w szkołach prywatnych zdawalność matur jest na wyższym poziomie niż w szkołach publicznych?",
    a:
      "Są takie badania, jednak sądzimy, że to wypadkowa zarówno potencjału nauczycieli, którzy wnoszą ogromny wkład w życie ucznia, jak i samozaparcia i potencjału poszczególnych uczniów. Bardzo liczymy na to, że dobrze zdana matura w naszej szkole będzie naturalną konsekwencją naszego programu opartego o praktyczne podejście w każdym aspekcie nauczania.",
  },
  {
    q: "Dlaczego nie ma Was w rankingach szkół?",
    a:
      "O to należałoby zapytać decydentów, dla których to szkoły publiczne mają priorytet w prezentowaniu tego typu placówek. My, jako szkoła niepubliczna, niestety nie mieliśmy szansy zaprezentować się w oficjalnym zestawieniu.",
  },
  {
    q:
      "Jeśli dziecko spędza dużo czasu przed komputerem i będzie też to robiło w szkole, to jest obawa, że zupełnie odetnie się od codzienności. Czy w szkole będą też zajęcia bez komputera?",
    a:
      "Zdecydowanie tak.  U nas zajęcia z WFu - prowadzone przez antyterrorystę - będą oparte nie tylko na zwykłych ćwiczeniach z programu szkolnego, ale też na elementach samoobrony. Prezentowanie różnych aspektów sportowych możliwości i pozalekcyjnych zajęć związanych ze zdrowym funkcjonowaniem w sportowym aspekcie będzie naszą misją.",
  },
  {
    q:
      "Podobno w Waszej szkole nie trzeba robić notatek w zeszytach. Jak to będzie wyglądało?",
    a:
      "Nasi uczniowie mają do wyboru prowadzenie notatek w klasycznych zeszytach albo w komputerach (które zresztą otrzymują od szkoły). Podobnie rzecz ma się z ebookami. Jeśli uczeń ma życzenie aby posiadać książki w wersji elektronicznej - ma takie prawo.",
  },
];

export default function SimpleExpansionPanel() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {" "}
      {questions.map((element) => {
        return (
          <ExpansionPanel defaultExpanded={false}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="heading">{element.q}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className="faq-desc">{element.a}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czy w szkole istnieje możliwość realizacji indywidualnego programu
            lub toku nauki?{" "}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Tak, w szkole istnieje możliwość realizowania programu w podejściu
            indywidualnego programu lub toku nauczania. Zależy nam na osobistym
            rozwoju uczniów, stąd jeśli istnieją przesłanki (wybitne zdolności
            ucznia, wybitne wyniki w nauce, aktywny udział w olimpiadach
            tematycznych) na wsparcie ucznia z wykorzystaniem takich narzędzi.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czym różni się technikum od liceum ogólnokształcącego?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Technikum trwa 5 lat (rok dłużej niż w licem) i przygotowuje do
            zawodu, dzięki czemu po ukończeniu szkoły absolwent posiada zawód
            technika programisty. Każdy absolwent naszej szkoły będzie
            przygotowany by móc rozpocząć pracę jako specjalista w branży IT,
            m.in. Jako: programista, tester, project manager, scrum master,
            bazodanowiec, data engineer, specjalista od sztucznej inteligencji,
            dev ops.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czy po nauce w technikum można zdawać maturę i iść na studia wyższe?{" "}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Oczywiście. Technikum przygotowuje również do matury, po której
            zdaniu, można zapisać się na studia wyższe by rozwinąć się również w
            nauce. Każdy absolwent naszej szkoły z wielką łatwością będzie mógł
            poruszać się po najlepszych uczelniach krajowych i zagranicznych.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czy istnieje możliwość zdawania matury z dowolnych przedmiotów?
            Które przedmioty są rozszerzone?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Oprócz jednego obowiązkowego egzaminu z przedmiotu dodatkowego na
            poziomie ‎rozszerzonym, można przystąpić do egzaminów z nie więcej
            niż pięciu kolejnych ‎przedmiotów. ‎ Wyboru można dokonać spośród
            niżej wymienionych przedmiotów.‎ W części pisemnej:‎
            <br />
            biologia (na poziomie rozszerzonym)‎
            <br />
            chemia (na poziomie rozszerzonym)‎
            <br />
            filozofia (na poziomie rozszerzonym)‎
            <br />
            fizyka (na poziomie rozszerzonym)‎
            <br />
            geografia (na poziomie rozszerzonym)‎
            <br />
            historia (na poziomie rozszerzonym)‎
            <br />
            historia muzyki (na poziomie rozszerzonym)‎
            <br />
            historia sztuki (na poziomie rozszerzonym)‎
            <br />
            informatyka (na poziomie rozszerzonym)‎
            <br />
            język angielski (na poziomie rozszerzonym ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język francuski (na poziomie rozszerzonym ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język hiszpański (na poziomie rozszerzonym ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język niemiecki (na poziomie rozszerzonym ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język rosyjski (na poziomie rozszerzonym ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język włoski (na poziomie rozszerzonym ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język łaciński i kultura antyczna (na poziomie rozszerzonym)‎
            <br />
            język białoruski jako język mniejszości narodowej (na poziomie
            rozszerzonym)‎
            <br />
            język litewski jako język mniejszości narodowej (na poziomie
            rozszerzonym)‎
            <br />
            język niemiecki jako język mniejszości narodowej (na poziomie
            rozszerzonym)‎
            <br />
            język ukraiński jako język mniejszości narodowej (na poziomie
            rozszerzonym)‎
            <br />
            język łemkowski jako język mniejszości etnicznej (na poziomie
            rozszerzonym)‎
            <br />
            język kaszubski jako język regionalny (na poziomie rozszerzonym)‎
            <br />
            język polski (na poziomie rozszerzonym)‎
            <br />
            matematyka (na poziomie rozszerzonym)‎
            <br />
            wiedza o społeczeństwie (na poziomie rozszerzonym)
            <br />
            W części ustnej:‎
            <br />
            język angielski (bez określania poziomu ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język francuski (bez określania poziomu ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język hiszpański (bez określania poziomu ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język niemiecki (bez określania poziomu ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język rosyjski (bez określania poziomu ALBO na poziomie
            dwujęzycznym)‎
            <br />
            język włoski (bez określania poziomu ALBO na poziomie dwujęzycznym)‎
            <br />
            język białoruski jako język mniejszości narodowej (bez określania
            poziomu)‎
            <br />
            język litewski jako język mniejszości narodowej (bez określania
            poziomu)‎
            <br />
            język niemiecki jako język mniejszości narodowej (bez określania
            poziomu)‎
            <br />
            język ukraiński jako język mniejszości narodowej (bez określania
            poziomu)‎
            <br />
            język łemkowski jako język mniejszości etnicznej (bez określania
            poziomu)‎
            <br />
            język kaszubski jako język regionalny (bez określania poziomu)‎
            <br />W zależności od wyborów dokonanych w części obowiązkowej
            egzaminu, wybór przedmiotów ‎dodatkowych (w części pisemnej i/lub
            części ustnej) może być ograniczony. Szczegółowo wszystkie zasady
            ‎są opisane tutaj (sekcje C i D). Więcej informacji można odnaleźć
            na stronie CKE:
            <a
              style={{ color: "#000" }}
              href="https://cke.gov.pl/egzamin-maturalny/egzamin-w-nowej-formule/o-egzaminie/"
            >
              Link
            </a>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Dlaczego warto wybrać technikum a nie liceum ogólnokształcące?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Jeśli wybierzesz technikum, po ukończeniu szkoły będziesz mógł
            zadecydować czy iść na studia wyższe, czy jednak pójść do pracy. Być
            może wybierzesz studia zaoczne by móc pogodzić obowiązki. Niestety
            po liceum, nie będziesz przygotowany do zawodu.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Dlaczego warto zostać technikiem programistą?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Poniżej przedstawionych jest 7 korzyści, dlaczego warto zostać
            programistą według Michała Molendy:
            <br /> 1. Programowanie wymaga logicznego myślenia. Jeśli coś to
            coś, jeśli nie to coś innego. Wspomaga skupienie oraz analityczne
            podejście do rozwiązywania problemów. To bardzo ważne, szczególnie
            dla młodszych osób, których mózg dopiero się rozwija.
            <br /> 2. Nawet jeśli pieniądze nie są dla nas najważniejsze, to
            jednak każdy z nas pracuje w celach zarobkowych. W IT zarobki są
            wysokie, co otwiera dużo drzwi i pozwala żyć na dobrym poziomie. W
            obecnej sytuacji, kiedy jest mniej programistów niż miejsc pracy,
            firmy oferują wysokie stawki nawet początkującym.
            <br /> 3. Pracując jako programista, szczególnie w korporacji, masz
            bardzo duże możliwości rozwoju zawodowego. Możesz dalej rozwijać się
            w programowaniu, zostać project managerem, specjalistą od baz
            danych, kierownikiem zespołu czy testerem. Możliwości jest bardzo
            dużo, każdy znajdzie coś dla siebie.
            <br />
            4. Wiele firm potrzebuje usług programistycznych w małym zakresie.
            Jeśli zechcesz, możesz dorobić wykonując zlecenia po pracy. Oprócz
            korzyści finansowych, to doskonała możliwość nauczenia się nowych
            rzeczy i zdobycia cennych kontaktów. <br />
            5. Można powiedzieć, że programiści żyją obecnie jak pączki w maśle.
            Serio. Jest większe zapotrzebowanie, niż chętnych. Firmy prześcigają
            się w oferowaniu dobrych warunków i benefitów najlepszym kandydatom.
            Oprócz zarobków możesz liczyć na prywatną opiekę medyczną,
            dofinansowanie nauki języków czy karnet na siłownię.
            <br /> 6. Znając język angielski możesz pracować w dowolnym kraju.
            Firmy chętnie ściągają dobrych programistów do siebie, często
            pomagając przy tym przy relokacji oraz zapewniając lepsze warunki i
            zarobki.
            <br /> 7. Na koniec najważniejszy (przynajmniej dla mnie :)) punkt.
            Praca zdalna. Jest super, szczególnie jeśli pracujemy z małej
            wysepki w Tajlandii. Na pewno poświęcę temu zagadnieniu jeszcze
            kilka wpisów. <br />
            Zachęcamy do lektury:{" "}
            <a style={{ color: "#000" }} href="https://michalmolenda.pl/">
              {" "}
              Link
            </a>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czym różni się technik programista od technika informatyka?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Technik informatyk w swoim programie ma większy nacisk na poznanie
            ogólnej informatyki oraz umiejętności administracji systemów oraz
            sieci komputerowych. Program Technika Programisty skupia się na
            wybudowaniu dobrych umiejętności programistycznych, poznaniu wielu
            języków programowania oraz technologii. Absolwenci naszej szkoły
            będą mogli rozpocząć pracę m.in. jako programista, tester, project
            manager, scrum master, bazodanowiec, data engineer, specjalista od
            sztucznej inteligencji, dev ops.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Jak będą wybierani nauczyciele przedmiotów zawodowych w Techni
            Schools?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Przedmiotów zawodowych będą uczyli praktycy rynkowi tzn.
            programiści, specjaliści w swojej dziedzinie którzy pracują na co
            dzień w firmach informatycznych i swoją misją chcą zarazić młodszych
            od siebie oraz przekazać to czego się nauczyli w ostatnich latach.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Co muszę zrobić żeby dostać się do Techni Schools?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Stwórz konto, uzupełnij formularz, który zostanie podpisany przez
            Twojego Opiekuna a następnie rozwiąż test. W momencie kiedy
            otrzymasz świadectwo oraz wyniki testu ośmioklasisty wrzuć skany do
            systemu. Przez cały okres rekrutacji będziesz na bieżąco informowany
            o wynikach. Regulamin odnajdziesz tutaj:{" "}
            <Link
              to="/files/RegulaminRekrutacji.pdf"
              style={{ textDecoration: "none", color: "#000" }}
              download
              target="_blank"
            >
              Regulamin rekrutacji
            </Link>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">Jak wygląda test?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Test składa się z prostych zadań matematycznych, informatycznych
            oraz z języka angielskiego. Nie trzeba zaliczyć go w 100% żeby
            dostać się do Technikum. Test ma na celu zrozumienie na jakim
            poziomie jest uczeń i jakich narzędzi należy użyć na początku
            edukacji by było to dla ucznia komfortowe.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czy jeżeli postanowię, że nie chcę zostać programistą to będę mógł
            zmienić szkołę np. po pierwszej klasie?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Czy jeżeli postanowię, że nie chcę zostać programistą to będę mógł
            zmienić szkołę np. po pierwszej klasie? Polecamy ukończyć szkołę i
            wtedy zmienić swój kierunek rozwoju. Jednakże jeśli będzie potrzeba
            zmiany szkoły, jest taka możliwość unormowana przez Oświatę.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">Jakie są opłaty w szkole?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Miesięczna opłata czesnego wynosi 1 500 zł w Lublinie i Rzeszowie
            oraz 2 000 zł w innych miastach. <br />
            Opłata rekrutacyjna wynosi 2 000 zł i należy ją opłacić w ciągu 14
            dni od podpisania umowy.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Co jest dodatkowo wliczone w cenę?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            W cenie wliczone są:
            <br />
            • indywidualny sprzęt dla każdego ucznia w postaci laptopa, na
            którym będzie mógł pracować przez cały okres trwania edukacji
            zarówno w szkole, jak i w domu;
            <br />
            • pakiet narzędzi i programów do tworzenia oprogramowania;
            <br />
            • materiały dydaktyczne służące do nauki zawodu;
            <br />
            • dodatkowe zajęcia – bootcampy, służące do pogłębionej nauki
            specjalistycznych elementów, które będą prowadzone przez mentorów
            technologicznych;
            <br />• darmowy dostęp do wydarzeń i meetup’ów organizowanych przez
            grupę Techni.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czy istnieje możliwość zwolnienia z opłat?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Najlepsi uczniowie mogą otrzymać stypendium w wysokości czesnego,
            które są finansowane przez szkołę oraz patronów szkoły.
            <br />
            Istnieje również możliwość otrzymania kredytu na czesne. Po więcej
            informacji zapraszamy do bezpośredniego kontaktu podanego na
            stronie.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">
            Czy w związku z COVID-19 są jakieś zagrożenia dla działalności
            szkoły?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="faq-desc">
            Techni Schools jest przygotowane do pracy i edukacji zdalnej. W
            ramach programu nauczania wykorzystujemy najnowsze technologie
            wirtualizacji zajęć oraz przeprowadzania zajęć w odpowiednim
            środowisku. Wobec takiego charakteru szkoły, dostosowanie się do
            zajęć wirtualnych jest bardzo prostym zadaniem.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
