import React, { Component } from 'react'
import '../styles/panel.css'
import WhiteLogo from '../images/svg/whiteLogo'
import Step from '../components/step'
import Step1 from '../components/step1'
import Step2 from '../components/step2'
import Step3 from '../components/step3'
import Step4 from '../components/step4'
import StepAdm from '../components/stepAdm'
import { Query, withApollo } from 'react-apollo'
import LinearProgress from '@material-ui/core/LinearProgress'
import NearMeIcon from '@material-ui/icons/NearMe'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import history from '../utils/history'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Typography } from '@material-ui/core'

import {ADM_QUERY, COMPRESSED_QUERY, DOC_QUERY, ME_QUERY, USR_QUERY, USR_RESPONSES} from '../mw/queries';

class PanelPage extends Component {
  constructor (props) {
    super(props)
    this.s1 = React.createRef()
  }
  state = {
    stepActive: 0,
    firstName: '',
    lastName: '',
    step1: false,
    step2: false,
    newStep: 0,
    loaded: false,
    stepsCompleted: 0,
    users: [],
    userData: null,
    userResponses: null,
    userFile: ''
  }
  async componentDidMount () {
    const token = await localStorage.getItem('token')
    if (token === null) {
      history.push('/login')
    }
  }

  scrollToMyRef = async ref => {
    await window.scrollTo(0, ref.current.offsetTop)
  }
  scrollTop = async () => {
    await window.scrollTo(0, this.s1.current.offsetTop)
  }
  runMeQuery () {
    this.props.client
      .query({
        query: ME_QUERY
      })
      .then(results => {
        this.setState({
          stepActive: results.data.me.activeStep,
          stepsCompleted: results.data.me.activeStep - 1
        })
      })
  }

  runQuery () {
    this.props.client
      .query({
        query: ADM_QUERY
      })
      .then(results => {
        this.setState({ users: results.data.users })
      })
  }

  renderUserQuery = async id => {
    this.setState({ userData: null, userResponses: {}, userFile: '' })
    await this.props.client
      .query({
        query: USR_QUERY,
        variables: {
          id
        }
      })
      .then(results => {
        this.setState({ userData: results.data })
      })
      .catch(err => {})

    await this.props.client
      .query({
        query: USR_RESPONSES,
        variables: {
          id
        }
      })
      .then(results => {
        this.setState({ userResponses: results.data })
      })
      .catch(err => {})

    await this.props.client
      .query({
        query: DOC_QUERY,
        variables: {
          id
        }
      })
      .then(results => {
        this.setState({ userFile: results.data })
      })
      .catch(err => {})

    await this.props.client
      .query({
        query: COMPRESSED_QUERY,
        variables: {
          id
        }
      })
      .then(results => {
        this.setState({ userCompressed: results.data })
      })
      .catch(err => {})
    let obj = {
      userData: this.state.userData,
      userFile: this.state.userFile,
      userResponses: this.state.userResponses,
      userCompressed: this.state.userCompressed
    }
    this.setState({ userObject: obj })
  }

  onRefresh = async () => {
    await window.scrollTo(0, this.s1.current.offsetTop)
    await this.runMeQuery()
    this.forceUpdate()
  }

  render () {
    return (
      <Query query={ME_QUERY}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div className='container'>
                <LinearProgress />
              </div>
            )
          if (error) return <div>Error</div>
          if (this.state.loaded === false) {
            this.setState(
              {
                stepActive: data.me.activeStep,
                loaded: true
              },
              () => {
                this.setState({ stepsCompleted: this.state.stepActive - 1 })
              }
            )
            if (data.me.isAdmin === true) {
              this.runQuery()
            }
          }
          return (
            <React.Fragment>
              <div className='container-mobile'>
                <WhiteLogo
                  className='mobile-logo'
                  onClick={() => history.push('/')}
                />
                <Typography variant='h4' className='mobile-text'>
                  Aby przejść do rekrutacji, należy wejść na stronę z urządzenia
                  o większej rozdzielczości ekranu niż 1024×768.
                </Typography>
              </div>
              <div className='container-panel'>
                <div className='left-bar'>
                  <div className='avatar-container' ref={this.s1}>
                    <WhiteLogo className='corner-logo' />
                    <div className='user-container'>
                      <div className='rounded-avatar'>
                        <div className='avatar' />
                      </div>
                      <div className='personal-info'>
                        <span className='user-text'>
                          {data.me.name + ' ' + data.me.surname}
                        </span>
                        {data.me.isAdmin === true && (
                          <p className='user-text'>Administrator</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {data.me.isAdmin === false && (
                    <div className='navigation-bar'>
                      <div className='navigation-item label-active p-active'>
                        {' '}
                        <NearMeIcon />
                        <p className='navigation-item-label p-active'>
                          Rekrutacja
                        </p>
                      </div>
                      <div className='navigation-item'>
                        {' '}
                        <AssignmentIcon />
                        <p className='navigation-item-label'>Ogłoszenia</p>
                      </div>
                      <div className='navigation-item'>
                        {' '}
                        <CalendarTodayIcon />
                        <p className='navigation-item-label'>Plan lekcji</p>
                      </div>
                      <div className='navigation-item'>
                        {' '}
                        <ChromeReaderModeIcon />
                        <p className='navigation-item-label'>
                          Dziennik elektroniczny
                        </p>
                      </div>
                    </div>
                  )}
                  {this.state.users.length > 0 && (
                    <div className='navigation-bar'>
                      <p className='user-list-top-label'>Lista użytkowników:</p>
                      {this.state.users.map(elem => {
                        if (elem.isAdmin === true) {
                          return null
                        } else {
                          return (
                            <div
                              key={elem.id}
                              className='user-list-item'
                              onClick={() => {
                                this.renderUserQuery(elem.id)
                              }}
                            >
                              <p
                                className='user-list-item-label'
                                style={{
                                  color: elem.active === false ? 'red' : 'black'
                                }}
                              >
                                {elem.name + ' ' + elem.surname}
                              </p>
                            </div>
                          )
                        }
                      })}
                    </div>
                  )}
                </div>

                <div className='middle-panel'>
                  <div className='top-bar' />
                  <div className='main-panel'>
                    <div className='info-bar'>
                      <div className='steps-list'>
                        <p style={{ alignSelf: 'center' }}>
                          {data.me.isAdmin === false
                            ? 'Lista kroków'
                            : 'Panel administratora'}
                        </p>
                      </div>
                      {data.me.isAdmin === false && (
                        <div className='step-number'>
                          <p className='step-title-top'>
                            {this.state.stepActive === 1 &&
                              'KROK #1 Wypełnij formularz'}
                            {this.state.stepActive === 2 && 'KROK #2 Test'}
                            {this.state.stepActive === 3 &&
                              'KROK #3 Dostarcz dokumenty'}
                            {this.state.stepActive === 4 &&
                              'KROK #4 Czekaj na wyniki'}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className='middle-container'>
                      {data.me.isAdmin === false && (
                        <div className='steps'>
                          <Step
                            stepNumber='KROK #1'
                            id={1}
                            stepDesc='Wypełnij formularz'
                            isActive={this.state.stepActive}
                            completed={this.state.stepsCompleted}
                            onClick={() => {
                              this.setState({ stepActive: 1 })
                            }}
                          />
                          <Step
                            stepNumber='KROK #2'
                            id={2}
                            stepDesc='Test'
                            onClick={() => {
                              if (this.state.stepsCompleted === 1) {
                                this.setState({ stepActive: 2 })
                              }
                            }}
                            isActive={this.state.stepActive}
                            completed={this.state.stepsCompleted}
                          />
                          <Step
                            stepNumber='KROK #3'
                            id={3}
                            stepDesc='Dostarcz dokumenty'
                            isActive={this.state.stepActive}
                            onClick={() => {
                              if (this.state.stepsCompleted >= 2) {
                                this.setState({ stepActive: 3 })
                              }
                            }}
                            completed={this.state.stepsCompleted}
                          />
                          <Step
                            stepNumber='KROK #4'
                            id={4}
                            stepDesc='Czekaj na wyniki'
                            isActive={this.state.stepActive}
                            onClick={() => {
                              if (this.state.stepsCompleted >= 3) {
                                this.setState({ stepActive: 4 })
                              }
                            }}
                          />
                        </div>
                      )}

                      <div
                        className={
                          data.me.isAdmin === false
                            ? 'active-panel'
                            : 'active-panel-big'
                        }
                      >
                        {data.me.isAdmin === false &&
                          this.state.stepActive === 1 && (
                            <Step1
                              onRefresh={this.onRefresh}
                              scrollTop={this.scrollTop}
                            />
                          )}
                        {data.me.isAdmin === false &&
                          this.state.stepActive === 2 && (
                            <Step2
                              onRefresh={this.onRefresh}
                              scrollTop={this.scrollTop}
                            />
                          )}
                        {data.me.isAdmin === false &&
                          this.state.stepActive === 3 && (
                            <Step3
                              onRefresh={this.onRefresh}
                              scrollTop={this.scrollTop}
                            />
                          )}
                        {data.me.isAdmin === false &&
                          this.state.stepActive === 4 && (
                            <Step4
                              data={data.me}
                              onRefresh={this.onRefresh}
                              scrollTop={this.scrollTop}
                            />
                          )}

                        {data.me.isAdmin === true && (
                          <StepAdm
                            user={this.state.userObject}
                            scrollTop={this.scrollTop}
                            refreshPanel={this.renderUserQuery}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='right-bar'>
                  <div className='right-top'>
                    <ExitToAppIcon
                      style={{
                        color: '#fff',
                        fontSize: 32,
                        alignSelf: 'end',
                        cursor: 'pointer'
                      }}
                      onClick={async () => {
                        await localStorage.removeItem('token')
                        history.push('/')
                      }}
                    />
                  </div>
                  <div className='right-bottom' />
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(PanelPage)
