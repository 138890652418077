import React, { Component } from "react";
import "../styles/step1.css";
import TextField from "@material-ui/core/TextField";
import "../styles/step.css";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import { DropzoneArea } from "material-ui-dropzone";
import FileSaver from "file-saver";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";

import {
  PERSONAL_DATA_MUTATION,
  UPLOAD_FILE_STEP1_MUTATION,
} from "../mw/mutations";

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "50%",
    paddingTop: "5%",
    flexDirection: "column",
    paddingLeft: "3%",

    "& .MuiTypography-h6": {
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: 19,
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: 16,
      },
    },
    "& .MuiInputLabel-root": {
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: 15,
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: 12,
      },
    },
  },
  grid: {
    "& .MuiInputLabel-root": {
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: 12,
      },
    },
  },
});

class Step1 extends Component {
  state = {
    firstName: "",
    birthdate: "",
    birthcity: "",
    pesel: "",
    address: "",
    city: "",
    postal: "",
    country: "",
    inter1: "",
    inter2: "",
    achievments: "",
    address2: "",
    city2: "",
    postal2: "",
    country2: "",
    p1name: "",
    p2name: "",
    p1address: "",
    p2address: "",
    p1phone: "",
    p2phone: "",
    p1email: "",
    p2email: "",
    p1workplace: "",
    p2workplace: "",
    p1job: "",
    p2job: "",
    fileBase64: null,
    check1: false,
    check2: true,
    check3: true,
    files: [],
    location: "",
    locid: null,
    errorFile: false,
    messageFile: "",
    checked: true,
  };

  onChange(files) {
    this.setState({ files, errorFile: false, messageFile: "" });
    if (files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        this.setState({ fileBase64: encoded });
      };
      reader.onerror = (err) => reader.abort();
    } else {
      this.setState({ fileBase64: null });
    }
  }
  handleCheckboxChange = (value) => {
    if (value === "check1") {
      let negative = !this.state.check1;
      this.setState({ check1: negative });
    } else if (value === "check2") {
      let negative = !this.state.check2;
      this.setState({ check2: negative });
    } else if (value === "check3") {
      let negative = !this.state.check3;
      this.setState({ check3: negative });
    }
  };

  validatePesel(pesel) {
    let reg = /^[0-9]{11}$/;
    if (reg.test(pesel) === false) return false;
    else {
      var weights = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
      var checksum = 0;
      for (var i = 0; i < weights.length; i++) {
        checksum += parseInt(pesel.substring(i, i + 1), 10) * weights[i];
      }
      checksum = checksum % 10;
      var isValid = checksum === parseInt(pesel.substring(10, 11), 10);
      return isValid;
    }
  }

  validate = () => {
    const {
      firstName,
      birthcity,
      birthdate,
      pesel,
      address,
      city,
      postal,
      country,
      check1,
      check2,
      check3,
      p1name,
      p1address,
      p1email,
      p1job,
      p1phone,
      p1workplace,
      p2address,
      p2email,
      p2job,
      p2name,
      p2phone,
      p2workplace,
      location,
      country2,
      inter1,
      inter2,
      achievments,
      postal2,
      address2,
      city2,
    } = this.state;

    const date_re = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    const postal_re = /^([0-9]{2})([-]{1}[0-9]{3})?$/;
    const phone_re = /\d{9}/;
    const email_re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let peselInfo = this.validatePesel(pesel);
    this.setState({ error: false, message: "" });
    if (
      firstName === "" ||
      birthcity === "" ||
      birthdate === "" ||
      pesel === "" ||
      address === "" ||
      city === "" ||
      postal === "" ||
      country === "" ||
      check1 === false ||
      check2 === false ||
      check3 === false ||
      p1name === "" ||
      p1address === "" ||
      p1email === "" ||
      p1job === "" ||
      p1phone === "" ||
      p1workplace === "" ||
      p2address === "" ||
      p2email === "" ||
      p2job === "" ||
      p2name === "" ||
      p2phone === "" ||
      p2workplace === "" ||
      location === ""
    ) {
      this.setState({
        error: true,
        message: "Proszę uzupełnić wszystkie pola!",
      });
      this.props.scrollTop();
    } else if (
      postal_re.test(postal) === false ||
      (postal2 !== "" && postal_re.test(postal2) === false) ||
      date_re.test(birthdate) === false ||
      peselInfo === false ||
      phone_re.test(p1phone) === false ||
      phone_re.test(p2phone) === false ||
      email_re.test(p1email) === false ||
      email_re.test(p2email) === false
    ) {
      this.setState({
        error: true,
        message: "Proszę poprawić pola!",
      });
      this.props.scrollTop();
    } else {
      this.props.client
        .mutate({
          mutation: PERSONAL_DATA_MUTATION,
          variables: {
            firstName,
            birthcity,
            birthdate,
            pesel,
            address,
            address2,
            city,
            city2,
            postal,
            postal2,
            country,
            country2,
            inter1,
            inter2,
            achievments,
            p1name,
            p1phone,
            p1workplace,
            p1address,
            p1email,
            p1job,
            p2name,
            p2phone,
            p2workplace,
            p2address,
            p2email,
            p2job,
            location,
          },
        })
        .then((data) => {
          const to = data.data.appendUser.output;
          const dataToPdf = data.data.appendUser.output.substring(
            2,
            to.length - 1
          );
          const blob = this.b64toBlob(dataToPdf, "application/pdf");
          FileSaver.saveAs(blob, "kwestionariusz.pdf");
        })
        .catch((error) => {
          //TODO HERE
          // console.log(error);
        });
    }
  };

  validateFile = () => {
    const { fileBase64 } = this.state;
    this.setState({ errorFile: false, messageFile: "" });
    if (fileBase64 === null) {
      this.setState({ errorFile: true, messageFile: "Proszę załączyć plik!" });
    } else {
      this.props.client
        .mutate({
          mutation: UPLOAD_FILE_STEP1_MUTATION,
          variables: {
            fileBase64,
          },
        })
        .then((data) => {
          this.props.onRefresh();
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  locationArray = [
    {
      id: 1,
      value: "warszawa",
    },
    {
      id: 2,
      value: "Lublin",
    },
    {
      id: 3,
      value: "Poznań",
    },
    {
      id: 4,
      value: "Wrocław",
    },
    {
      id: 5,
      value: "Gdańsk",
    },
    {
      id: 6,
      value: "Kraków",
    },
    {
      id: 7,
      value: "Rzeszów",
    },
    {
      id: 8,
      value: "Pruszków",
    },
  ];

  handleChaneSelect = (e) => {
    this.setState({
      locid: e.target.value,
      location:
        e.target.value === 0
          ? ""
          : this.locationArray[e.target.value - 1].value,
    });
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  render() {
    const { classes } = this.props;
    const date_re = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    const postal_re = /^([0-9]{2})([-]{1}[0-9]{3})?$/;
    const phone_re = /\d{9}/;
    const email_re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      <div className="form-container">
        <div className="form-container-top-bar">
          <div className="form-container-top-bar-left">
            Przed Tobą znajdują się cztery kroki rekrutacji. <br />W pierwszym
            kroku uzupełnij dane w formularzu oraz wydrukuj go. Następnie
            wydrukowany formularz, powinien zostać podpisany przez Opiekuna
            Prawnego i jego skan wrzucony do systemu.
            <br /> Po ukończonym kroku przejdź do rozwiązania testu.
          </div>
          <div className="form-container-top-bar-right"></div>
        </div>
        {this.state.error === true && this.state.message !== "" && (
          <Typography variant="h5" style={{ color: "red", marginTop: 20 }}>
            {" "}
            {this.state.message}
          </Typography>
        )}

        <div className="form-container-top">
          <div className={classes.root}>
            <Typography variant="h6">Dane osobowe kandydata</Typography>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <TextField
                  id="firstName"
                  style={{ fontSize: 10 }}
                  onChange={this.handleChange}
                  label="Imię/imiona i nazwisko kandydata"
                  value={this.state.firstName}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.firstName === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="birthdate"
                  onChange={this.handleChange}
                  value={this.state.birthdate}
                  fullWidth
                  placeholder="DD.MM.RRRR"
                  variant="outlined"
                  label="Data urodzenia"
                  inputProps={{
                    maxLength: 10,
                  }}
                  required
                  error={
                    (this.state.error === true && this.state.birthdate === ""
                      ? true
                      : false) ||
                    (this.state.birthdate === ""
                      ? false
                      : date_re.test(this.state.birthdate) === true
                      ? false
                      : true)
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="birthcity"
                  onChange={this.handleChange}
                  label="Miejsce urodzenia"
                  value={this.state.birthcity}
                  variant="outlined"
                  fullWidth
                  required
                  error={
                    this.state.error === true && this.state.birthcity === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={6} style={{ marginBottom: "10%" }}>
                <TextField
                  id="pesel"
                  onChange={this.handleChange}
                  label="PESEL"
                  value={this.state.pesel}
                  variant="outlined"
                  inputProps={{
                    maxLength: 11,
                  }}
                  fullWidth
                  required
                  error={
                    (this.state.error === true && this.state.pesel === ""
                      ? true
                      : false) ||
                    (this.state.pesel === ""
                      ? false
                      : this.validatePesel(this.state.pesel) === true
                      ? false
                      : true)
                  }
                />
              </Grid>
            </Grid>
            <Typography variant="h6">
              Informacje o uczniu, które chcielibyście <br /> Państwo przekazać
            </Typography>
            <p style={{ marginTop: 20 }}>
              {" "}
              Zainteresowania pozalekcyjne (kółka)
            </p>
            <Grid container spacing={4}>
              <Grid item xs={10} style={{ marginTop: "5%" }}>
                <TextField
                  id="inter1"
                  onChange={this.handleChange}
                  label="Przedmiotowe (jakie?)"
                  value={this.state.inter1}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="inter2"
                  onChange={this.handleChange}
                  label="Sportowe (jakie?)"
                  value={this.state.inter2}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="achievments"
                  onChange={this.handleChange}
                  label="Uzyskane osiągnięcia (olimpiady / konkursy / turnieje / certyfikaty)"
                  value={this.state.achievments}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.root}>
            <Typography variant="h6">Adres zamieszkania</Typography>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <TextField
                  id="address"
                  onChange={this.handleChange}
                  label="Ulica"
                  value={this.state.address}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.address === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 16,
                  width: "77%",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={6}>
                  <TextField
                    id="city"
                    onChange={this.handleChange}
                    label="Miasto"
                    value={this.state.city}
                    fullWidth
                    variant="outlined"
                    required
                    error={
                      this.state.error === true && this.state.city === ""
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="postal"
                    onChange={this.handleChange}
                    label="Kod pocztowy"
                    value={this.state.postal}
                    placeholder="XX-YYY"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 6,
                    }}
                    required
                    error={
                      (this.state.error === true && this.state.postal === ""
                        ? true
                        : false) ||
                      (this.state.postal === ""
                        ? false
                        : postal_re.test(this.state.postal) === true
                        ? false
                        : true)
                    }
                  />
                </Grid>
              </div>

              <Grid item xs={10} style={{ marginBottom: "10%" }}>
                <TextField
                  id="country"
                  onChange={this.handleChange}
                  label="Kraj"
                  value={this.state.country}
                  variant="outlined"
                  fullWidth
                  required
                  error={
                    this.state.error === true && this.state.country === ""
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>
            <Typography variant="h6">
              Adres zamieszkania (w przypadku, gdy jest inny od adresu
              zameldowania)
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={10} style={{ marginTop: "10%" }}>
                <TextField
                  id="address2"
                  onChange={this.handleChange}
                  label="Ulica"
                  value={this.state.address2}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 16,
                  width: "77%",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={6}>
                  <TextField
                    id="city2"
                    onChange={this.handleChange}
                    label="Miasto"
                    value={this.state.city2}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="postal2"
                    onChange={this.handleChange}
                    label="Kod pocztowy"
                    value={this.state.postal2}
                    fullWidth
                    placeholder="XX-YYY"
                    error={
                      this.state.postal2 === ""
                        ? false
                        : postal_re.test(this.state.postal2) === true
                        ? false
                        : true
                    }
                    inputProps={{
                      maxLength: 6,
                    }}
                    variant="outlined"
                  />
                </Grid>
              </div>

              <Grid item xs={10}>
                <TextField
                  id="country2"
                  onChange={this.handleChange}
                  label="Kraj"
                  value={this.state.country2}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="form-container-bottom">
          <div className={classes.root}>
            <Typography variant="h6">
              Dane rodziców (opiekunów) <br /> Matka (opiekun prawny)
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={10} style={{ marginTop: "10%" }}>
                <TextField
                  id="p1name"
                  onChange={this.handleChange}
                  label="Imię i nazwisko"
                  value={this.state.p1name}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p1name === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p1address"
                  onChange={this.handleChange}
                  label="Adres"
                  value={this.state.p1address}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p1address === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p1phone"
                  onChange={this.handleChange}
                  label="Telefon"
                  type="tel"
                  value={this.state.p1phone}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength: 9,
                  }}
                  required
                  error={
                    (this.state.error === true && this.state.p1phone === ""
                      ? true
                      : false) ||
                    (this.state.p1phone === ""
                      ? false
                      : phone_re.test(this.state.p1phone) === true
                      ? false
                      : true)
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p1email"
                  onChange={this.handleChange}
                  label="E-mail"
                  type="email"
                  value={this.state.p1email}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    (this.state.error === true && this.state.p1email === ""
                      ? true
                      : false) ||
                    (this.state.p1email === ""
                      ? false
                      : email_re.test(this.state.p1email) === true
                      ? false
                      : true)
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p1workplace"
                  onChange={this.handleChange}
                  label="Miejsce pracy"
                  value={this.state.p1workplace}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p1workplace === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p1job"
                  onChange={this.handleChange}
                  label="Zawód"
                  value={this.state.p1job}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p1job === ""
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.root}>
            <Typography variant="h6">
              Dane rodziców (opiekunów) <br /> Ojciec (opiekun prawny)
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={10} style={{ marginTop: "10%" }}>
                <TextField
                  id="p2name"
                  onChange={this.handleChange}
                  label="Imię i nazwisko"
                  value={this.state.p2name}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p2name === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p2address"
                  onChange={this.handleChange}
                  label="Adres"
                  value={this.state.p2address}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p2address === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p2phone"
                  onChange={this.handleChange}
                  label="Telefon"
                  type="tel"
                  inputProps={{
                    maxLength: 9,
                  }}
                  value={this.state.p2phone}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    (this.state.error === true && this.state.p2phone === ""
                      ? true
                      : false) ||
                    (this.state.p2phone === ""
                      ? false
                      : phone_re.test(this.state.p2phone) === true
                      ? false
                      : true)
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p2email"
                  onChange={this.handleChange}
                  label="E-mail"
                  type="email"
                  value={this.state.p2email}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    (this.state.error === true && this.state.p2email === ""
                      ? true
                      : false) ||
                    (this.state.p2email === ""
                      ? false
                      : email_re.test(this.state.p2email) === true
                      ? false
                      : true)
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p2workplace"
                  onChange={this.handleChange}
                  label="Miejsce pracy"
                  value={this.state.p2workplace}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p2workplace === ""
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="p2job"
                  onChange={this.handleChange}
                  label="Zawód"
                  value={this.state.p2job}
                  fullWidth
                  variant="outlined"
                  required
                  error={
                    this.state.error === true && this.state.p2job === ""
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="checkbox-container">
          <Typography variant="h6">
            {" "}
            W przypadku jednoosobowej opieki nad Kandydatem, proszę wpisać te
            same dane w obu kolumnach.
          </Typography>

          <FormControl style={{ width: "60%", marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">
              Lokalizacja wybranej szkoły*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="locid"
              value={this.state.locid}
              onChange={this.handleChaneSelect}
              error={
                this.state.error === true && this.state.location === ""
                  ? true
                  : false
              }
            >
              <MenuItem id="locid" value={1}>
                Warszawa
              </MenuItem>
              <MenuItem id="locid" value={2}>
                Lublin
              </MenuItem>
              <MenuItem id="locid" value={3}>
                Poznań
              </MenuItem>
              <MenuItem id="locid" value={4}>
                Wrocław
              </MenuItem>
              <MenuItem id="locid" value={5}>
                Gdańsk
              </MenuItem>
              <MenuItem id="locid" value={6}>
                Kraków
              </MenuItem>
              <MenuItem id="locid" value={7}>
                Rzeszów
              </MenuItem>
              <MenuItem id="locid" value={8}>
                Pruszków
              </MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h6">Oświadczenie</Typography>
          <FormGroup>
            <FormControlLabel
              style={{
                color:
                  this.state.check1 === false && this.state.error === true
                    ? "red"
                    : "black",
              }}
              control={
                <Checkbox
                  checked={this.state.check1}
                  onChange={() => this.handleCheckboxChange("check1")}
                  value="check1"
                  color="primary"
                />
              }
              label="Wyrażam zgodę na przekazywanie przez Administratora Danych Osobowych niezamówionych informacji na moją skrzynkę pocztową, zgodnie z Ustawą o świadczeniu usług drogą elektroniczną.*"
            />
            {/* <FormControlLabel
              style={{
                marginTop: 20,
                color:
                  this.state.check2 === false && this.state.error === true
                    ? "red"
                    : "black",
              }}
              control={
                <Checkbox
                  checked={this.state.check2}
                  onChange={() => this.handleCheckboxChange("check2")}
                  value="check2"
                  color="primary"
                />
              }
              label="Wyrażam zgodę na filmowanie dziecka/wykonywanie w czasie organizowanych przez Szkołę imprez zdjęć dziecku oraz ich publikację, m.in. na tablicy i/lub stronie Szkoły w związku z działaniami informacyjnymi oraz marketingowymi Szkoły.*"
            /> */}
            {/* <FormControlLabel
              style={{
                marginTop: 20,
                color:
                  this.state.check3 === false && this.state.error === true
                    ? "red"
                    : "black",
                disabled: true;
              }}
              control={
                <Checkbox
                  checked={this.state.check3}
                  onChange={() => this.handleCheckboxChange("check3")}
                  value="check3"
                  color="primary"
                />
              }
              label="Wyrażam zgodę na wykorzystywanie i rozpowszechnianie przez Szkołę prac dziecka wytworzonych pod kierunkiem nauczyciela na zajęciach szkolnych lub dla celów zajęć szkolnych.*"
            /> */}
          </FormGroup>

          <Button
            variant="outlined"
            onClick={this.validate}
            style={{
              borderColor: "#1B20A3",
              color: "#1B20A3",
              marginLeft: 30,
              alignSelf: "flex-end",
              borderRadius: 20,
              borderWidth: 2,
              marginTop: 20,
            }}
          >
            Drukuj
          </Button>
        </div>
        <div className="upload-container">
          {this.state.errorFile === true && this.state.messageFile !== "" && (
            <Typography variant="h5" style={{ color: "red", marginBottom: 20 }}>
              {" "}
              {this.state.messageFile}
            </Typography>
          )}
          <DropzoneArea
            dropzoneText="Dodaj podpisany formularz w formacie .pdf"
            onChange={this.onChange.bind(this)}
            dropzoneParagraphClass="dropzone"
            useChipsForPreview={true}
            filesLimit={1}
            acceptedFiles={["application/pdf"]}
          />
          <Button
            variant="contained"
            disableElevation
            onClick={this.validateFile}
            style={{
              backgroundColor: "#25017C",
              color: "#fff",
              marginLeft: 30,
              alignSelf: "flex-end",
              borderRadius: 20,
              borderWidth: 2,
              marginTop: 30,
            }}
          >
            Zakończ
          </Button>
        </div>
      </div>
    );
  }
}

Step1.propTypes = {
  styles: PropTypes.object.isRequired,
};

export default withApollo(withStyles(styles)(Step1));
