import React, { Component } from 'react'
import '../styles/step3.css'

export default class Step4 extends Component {
  render () {
    return (
      <div className='upload-container-step3' style={{paddingBottom: 0}}>
        <div
          className='form-container-top-bar-s3'
          style={{ height: 150, marginBottom: 0, marginTop: 0 }}
        >
          {this.props.data.waiRejAcc === 'waiting' && (
            <div
              className='form-container-top-bar-left-s3'
              style={{ fontSize: 18, width: '100%' }}
            >
              Trwa zliczanie punktów.
              <br />
              Wkrótce poinformujemy o wynikach.
              <br /> Zespół Techni Schools
            </div>
          )}
        {(this.props.data.waiRejAcc === 'accepted' || this.props.data.waiRejAcc === 'rejected') && (
            <div
              className='form-container-top-bar-left-s3'
              style={{ fontSize: 18, width: '100%' }}
            >
                {this.props.data.message}
            </div>
          )}
        </div>
        {this.props.data.waiRejAcc === 'waiting' && (
          <div className='waiting-img'></div>
        )}
        {this.props.data.waiRejAcc === 'accepted' && (
          <div className='accepted-img'></div>
        )}
        {this.props.data.waiRejAcc === 'rejected' && (
          <div className='rejected-img'></div>
        )}
      </div>
    )
  }
}
