import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withApollo } from "react-apollo";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import history from "../utils/history";
import TextField from "@material-ui/core/TextField";
import { ACTIVATE_MUTATION, NEW_LINK_MUTATION} from '../mw/mutations'

const dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}      
gtag("js", new Date());
gtag("config", "UA-160799982-1");
gtag('config', 'AW-747169502');
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-747169502/j8FpCKvcqc8BEN7No-QC',
      'event_callback': callback
  });
  return false;
}

const styles = theme => ({
  root: {
    flex: 7,
    "& .MuiTextField-root": {
      marginBottom: 20,
      width: "80%"
    },
    "& .MuiButton-root	": {
      marginTop: 40,
      borderRadius: 50,
      textTransform: "none",
      fontSize: 18,
      fontWeight: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 8,
      paddingBottom: 8
    }
  }
});

class ActivateForm extends Component {
  state = {
    activationKey: "",
    message: "",
    error: false,
    activated: false,
    oldKey: false,
    email: ""
  };

  
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  sendNewLink = () => {
    const { email } = this.state;
    this.props.client
      .mutate({
        mutation: NEW_LINK_MUTATION,
        variables: {
          email
        }
      })
      .then(data => {
        this.setState({ error: false, oldKey: false, message: "" });
        // console.log(data);
      })
      .catch(error => {
        this.setState({ error: true, message: error.graphQLErrors[0].message });
        // console.log(error);
      });
  };

  async componentDidMount() {
    const values = await queryString.parse(this.props.location);
    this.setState({ values, activationKey: values.key }, () => {});
    const activationKey = this.state.activationKey;
    this.props.client
      .mutate({
        mutation: ACTIVATE_MUTATION,
        variables: {
          activationKey
        }
      })
      .then(results => {
        // console.log(results);
        gtag_report_conversion();
        this.setState({ activated: true });
      })
      .catch(error => {
        if (error.graphQLErrors[0].message === "Klucz wygasł") {
          this.setState({
            oldKey: true,
            error: true,
            message:
              "Twój link aktywacyjny wygasł, naciśnij przycisk aby wysłać nowy."
          });
        } else {
          this.setState({ error: true, message: error });
        }
      });
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    const { classes } = this.props;
    const { activated, newLink } = this.state;
    return (
      <form className={classes.root} noValidate autoComplete="off">
        
        {activated === true && (
          <p className="light-text-green">Twoje konto zostało aktywowane</p>
        )}
        {newLink === true && (
          <p className="light-text-green">
            Wysłano nowy link aktywacyjny na podany adres email.
          </p>
        )}
        {this.state.message !== "" &&
          this.state.error === true &&
          this.state.oldKey === false && (
            <p className="light-text-red">
              Wystąpił błąd, spróbuj ponownie później.
            </p>
          )}
        {this.state.oldKey === true &&
          this.state.error === true &&
          this.state.message === "" && (
            <p className="light-text-red">
              Twój link aktywacyjny wygasł, wpisz adres email i naciśnij
              przycisk aby wysłać nowy.
            </p>
          )}
        {this.state.oldKey === true &&
          this.state.error === true &&
          this.state.message !== "" && (
            <p className="light-text-red">{this.state.message}</p>
          )}
        {this.state.error === false && (
          <Button
            variant="contained"
            style={{ backgroundColor: "#25017C", color: "#fff" }}
            disableElevation
            onClick={() => history.push("/login")}
          >
            Zaloguj się
          </Button>
        )}
        {this.state.error === true && this.state.oldKey === true && (
          <div style={{ marginTop: 20 }}>
            <TextField
              id="email"
              style={{ fontSize: 10 }}
              onChange={this.handleChange}
              label="Podaj adres email."
              value={this.state.email}
              fullWidth
              type="email"
              variant="outlined"
            />
            <Button
              variant="contained"
              style={{ backgroundColor: "#25017C", color: "#fff" }}
              disableElevation
              onClick={this.sendNewLink}
            >
              Wyślij ponownie
            </Button>
          </div>
        )}
      </form>
    );
  }
}
ActivateForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApollo(ActivateForm));
