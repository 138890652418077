import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Mutation } from "react-apollo";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {SEND_EMAIL_MUTATION} from '../mw/mutations';

const styles = (theme) => ({
  root: {
    flex: 7,
    "& .MuiTextField-root": {
      marginBottom: 20,
      width: "80%",
    },
    "& .MuiButton-root	": {
      marginTop: 40,
      borderRadius: 50,
      textTransform: "none",
      fontSize: 18,
      fontWeight: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
});

class LostPasswordForm extends Component {
  state = {
    email: "",
    message: "",
    error: false,
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    const { classes } = this.props;
    const { email } = this.state;
    return (
      <form className={classes.root} noValidate autoComplete="off">
        {this.state.message === "" && (
          <p className="light-text">Podaj swój e-mail, aby zresetować hasło.</p>
        )}
        {this.state.message !== "" && this.state.error === false && (
          <p className="light-text-green">{this.state.message}</p>
        )}
        {this.state.message !== "" && this.state.error === true && (
          <p className="light-text-red">
            Nie istnieje uzytkownik o takim adresie email.
          </p>
        )}
        <div>
          <TextField
            id="email"
            label="E-mail"
            value={this.state.email}
            onChange={this.onChange}
          />
        </div>
        <Mutation
          mutation={SEND_EMAIL_MUTATION}
          variables={{ email }}
          onCompleted={(data) => {
            this.setState({
              message: "Link do zmiany hasła został wysłany na adres email.",
              error: false,
            });
          }}
          onError={(error) => {
            this.setState({
              message: "Podano błędne dane logowania.",
              error: true,
            });
          }}
        >
          {(resetMutation) => (
            <Button
              variant="contained"
              style={{ backgroundColor: "#25017C", color: "#fff" }}
              disableElevation
              onClick={resetMutation}
            >
              Wyślij hasło
            </Button>
          )}
        </Mutation>
      </form>
    );
  }
}
LostPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LostPasswordForm);
