import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import history from "../utils/history";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";

import {REGISTER_MUTATION} from '../mw/mutations'


const styles = theme => ({
  root: {
    flex: 3,
    "& .MuiTextField-root": {
      marginBottom: 15,
      width: "80%"
    },
    "& .MuiButton-root	": {
      marginTop: 40,
      borderRadius: 50,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: "#25017C",
      color: "#fff",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 10
    },
    "& .MuiTypography-body1  ": {
      fontSize: 12
    }
  }
});

class RegisterForm extends Component {
  state = {
    name: "",
    surname: "",
    email: "",
    password: "",
    passwordRepeat: "",
    registered: null,
    message: "",
    rodo: false,
    policy: false,
    mails: false,
    site: false,
    error: false
  };

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    if (token !== null) {
      history.push("/panel");
    }
  }

  validateFields = async () => {
    const {
      email,
      password,
      name,
      surname,
      passwordRepeat
      // rodo,
      // policy,
      // mails,
      // site
    } = this.state;

    const password_re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-\/.:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-/./:;<=>?@[\]^_`{|}~]{8,}$/;
    const email_re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    await this.setState({ error: false, message: "" });
    if (
      name === "" ||
      surname === "" ||
      email === "" ||
      password === "" ||
      passwordRepeat === ""
    ) {
      await this.setState({
        error: true,
        message: "Proszę uzupełnić wszystkie pola!"
      });
    } else if (email_re.test(email) === false) {
      await this.setState({
        error: true,
        message: "Podano niepoprawny adres email!"
      });
    } else if (password !== passwordRepeat) {
      this.setState({ error: true, message: "Hasła nie są jednakowe!" });
    } else if (password_re.test(password) === false) {
      await this.setState({
        error: true,
        message:
          "Hasło musi składać się z przynajmniej ośmiu znaków, jednej cyfry, jednej wielkiej litery, jednej małej litery, oraz znaku specjalnego!"
      });
    } else {
      if (this.state.error === false && this.state.message === "") {
        this.props.client
          .mutate({
            mutation: REGISTER_MUTATION,
            variables: {
              email,
              password,
              name,
              surname
            }
          })
          .then(data => {
            this.setState({ registered: true, message: "" });
          })
          .catch(error => {
            if (
              error.graphQLErrors[0].message ===
              "UNIQUE constraint failed: users_user.email"
            ) {
              this.setState({
                message:
                  "Istnieje już konto zarejestrowane na taki adres email."
              });
            } else {
              this.setState({ message: error.graphQLErrors[0].message });
            }
          });
      }
    }
  };
  handleCheckboxChange = value => {
    if (value === "rodo") {
      let negative = !this.state.rodo;
      this.setState({ rodo: negative });
    } else if (value === "policy") {
      let negative = !this.state.policy;
      this.setState({ policy: negative });
    } else if (value === "mails") {
      let negative = !this.state.mails;
      this.setState({ mails: negative });
    } else {
      let negative = !this.state.site;
      this.setState({ site: negative });
    }
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { registered, message } = this.state;
    const { classes } = this.props;
    return (
      <form className={classes.root} noValidate autoComplete="off">
        {registered === null && message === "" && (
          <p className="light-text">Wypełni pola, by stworzyć konto.</p>
        )}
        {registered === true && message === "" && (
          <p className="light-text-green">
            Konto zostało dodane, kod aktywacyjny został wysłany na adres
            e-mail.
          </p>
        )}
        {message !== "" && (
          <p className="light-text-red">{this.state.message}</p>
        )}
        <div>
          <TextField
            id="name"
            label="Imię"
            onChange={this.onChange}
            value={this.state.name}
          />
        </div>
        <div>
          <TextField
            id="surname"
            label="Nazwisko"
            onChange={this.onChange}
            value={this.state.surname}
          />
        </div>
        <div>
          <TextField
            id="email"
            label="E-mail"
            onChange={this.onChange}
            value={this.state.email}
          />
        </div>
        <div>
          <TextField
            id="password"
            label="Hasło"
            type="password"
            onChange={this.onChange}
            value={this.state.password}
          />
        </div>
        <div>
          <TextField
            type="password"
            id="passwordRepeat"
            label="Powtórz hasło"
            onChange={this.onChange}
            value={this.state.passwordRepeat}
          />
        </div>
        <p
          style={{ paddingLeft: "10%", textAlign: "left", paddingRight: "10%" }}
        >
          Klikając zarejestuj się oświadczasz że zapoznałeś się oraz akceptujesz{" "}
          {"  "}
          <a
            style={{ color: "#000" }}
            target="_blank"
            href="/files/RegulaminStrony.pdf"
          >
            Regulamin aplikacji
          </a>
          , {"  "}
          <a
            style={{ color: "#000" }}
            target="_blank"
            href="/files/RegulaminRekrutacji.pdf"
          >
            Regulamin Rekrutacji
          </a>{" "}
          oraz{" "}
          <a
            style={{ color: "#000" }}
            target="_blank"
            href="/files/PolitykaPrywatnosci.pdf"
          >
            Politykę Prywatności
          </a>
          {"  "}
          oraz wyrażasz zgodę na otrzymywanie drogą elektroniczną informacji o
          aktualizacjach w procesie rekrutacji pochodzących od Techni Schools
          sp. z.o.o z siedzibą w Lublinie.
        </p>
        <Button onClick={this.validateFields} variant="contained">
          Zarejestruj się
        </Button>
        <p
          className="login-text"
          onClick={() => {
            history.push("/login");
          }}
        >
          Masz już konto? Zaloguj się
        </p>
      </form>
    );
  }
}
RegisterForm.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withApollo(withStyles(styles)(RegisterForm));
