import React from "react";
import Typography from "@material-ui/core/Typography";
import "../styles/landing.css";
const Page404 = () => (
  <div className="page404">
    <Typography variant="h2" align="center">
      404 Not Found
    </Typography>
  </div>
);

export default Page404;
