import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import LostPasswordPage from "./pages/LostPasswordPage";
import PanelPage from "./pages/PanelPage";
import LandingPage from "./pages/LandingPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import ActivatePage from "./pages/ActivatePage";
import Page404 from "./pages/404Page";

function App() {
  return (
    <div>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/section/:id" component={LandingPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/lost_password" component={LostPasswordPage} />
          <Route path="/panel" component={PanelPage} />
          <Route path="/reset" component={NewPasswordPage} />
          <Route path="/activate" component={ActivatePage} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
